// import contactbgimage from '../image/gamingbg.png'; 
import React, { useState } from 'react';
import contactformimage from '../../media/contactformimg.jpg';
import { ToastContainer, toast } from 'react-toastify';


import './contact.css';
function Contact() {

    const [UName, setUName] = useState("")
    const [UEmail, setUEmail] = useState("")
    const [UNumber, setUNumber] = useState("")
    const [UMessage, setUMessage] = useState("")
    const [ThankYouMsg, setThankYouMsg] = useState(false)
    return (
        <>
            <div className='main_contact_page'>
                <div className='contact_bg_image_division'>
                    <h2 className='contact_heading'>contact us</h2>
                </div>

                <div class="container text-center contact_info_main_division">
                    <div class="row">
                        <div class="col contact_division">
                            <i class="fa-solid fa-mobile-screen-button contact_phone_icon"></i>
                            <p className='contact_phone_title'>Phone</p>
                            <p className='contact_number'><a href='tel:(+91) 7383977771' style={{ color: "white", textDecoration: "none" }}> (+91) 7383977771</a></p>
                            <p className='contact_number'><a href='tel:(+91) 8866656093' style={{ color: "white", textDecoration: "none" }}>(+91) 8866656093</a></p>
                        </div>
                        <div class="col address_division">
                            <i class="fa-sharp fa-solid fa-location-dot contact_address_icon"></i>
                            <p className='contact_address_title text-white'>Location</p>
                            <p className='contact_address'> <a href='https://goo.gl/maps/oKNstrnozYVDV9fG6' target="_blank" style={{ color: "white", textDecoration: "none" }}>FF-25, Sun Rise Shops & Flats, Waghodia Rd, near Vrundavan Crossing, Vadodara, 390019</a></p>
                        </div>
                        <div class="col email_division">
                            <i class="fa-regular fa-envelope contact_email_icon"></i>
                            <p className='contact_email_title'>E-mail</p>
                            <p className='contact_email'><a href='mailto:contact@k9gaming.in' style={{ color: "white", textDecoration: "none" }}>contact@k9gaming.in</a></p>
                        </div>
                    </div>
                </div>
                <div class="container my-5">

                    <div class="row">
                        <h3 className='contact_title text-center mt-5'>get in touch with us!</h3>
                        <div class="col contact_form_image">
                            <img className='contact_bg_image' src={contactformimage} alt='img'></img>
                        </div>
                        <div class="col contact_form_division">
                            {ThankYouMsg ? <>
                               

                                <div className='contact_form_after_main_division'>
                                <div>
                                <div className="wrapper"> <svg class="Msgcheckmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /> <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg></div>
                                    <h3 className='contact_form_after_division text-center mt-3'>Thank you for your response. We'll be in touch with you shortly.</h3>
                                </div>
                                </div>
                            </>
                                : <div className='contact_form_after_main_division_form'>

                                    <div>
                                        <input type="text" class="form-control contact_fild_first_name" placeholder='Full Name' id="UName"
                                            onChange={(e) => {
                                                setUName(e.target.value)
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <input type="email" class="form-control contact_fild_email" placeholder='Email' id="UEmail"
                                            onChange={(e) => {
                                                setUEmail(e.target.value)

                                            }}
                                        />
                                    </div>
                                    <div>
                                        <input type="text" class="form-control contact_fild_email" placeholder='Contact Number' id="UNumber"
                                            onChange={(e) => {

                                                setUNumber(e.target.value)
                                            }}
                                        />
                                    </div>

                                    <div>
                                        <input type="text" class="form-control contact_fild_massage" id="inputAddress" placeholder="Message"
                                            onChange={(e) => {
                                                setUMessage(e.target.value)

                                            }}
                                        />
                                    </div>

                                    <div>
                                        <button type="submit" class="btn contact_form_btn"
                                            onClick={(e) => {
                                                var url = window.API_PREFIX + "user/contactusmail";

                                                fetch(url, {
                                                    method: 'POST',
                                                    headers: {
                                                        'Content-type': 'application/json',
                                                    },
                                                    body: JSON.stringify({
                                                        "Name": UName,
                                                        "Email": UEmail,
                                                        "Number": UNumber,
                                                        "AdditionMessage": UMessage,
                                                    }),
                                                }).then(resp => resp.json()).then(data => {

                                                    if (data['status'] == "1") {
                                                        setThankYouMsg(true)
                                                    }
                                                    else
                                                    {console.log("Failed")
                                                        toast.error('Something went wrong! Please check your email', {
                                                            position: "top-center",
                                                            autoClose: 4000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: true,
                                                            progress: undefined,
                                                            });
                                                    }
                                                })
                                            }}
                                        >Send</button>
                             <ToastContainer/>

                                    </div>
                                </div>}
                        </div>
                    </div>
                </div>
                {/* map-start */}
                <div className='contact_map'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3691.4690908432462!2d73.24818069999999!3d22.298092299999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395fc51a7e16037d%3A0xe37e5c6e3a55cbf8!2sK9Gaming.in!5e0!3m2!1sen!2sin!4v1670486607059!5m2!1sen!2sin" width="100%" height="450" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                {/* map-end */}
            </div>
        </>
    )
}
export default Contact;