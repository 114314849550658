

import React, { useState, useEffect, useLayoutEffect, useRef } from "react";

// import "./booknowbutton.css"
import K9logo from '../media/K9 Gaming Logo Website.png'
import './Toolscomp/Pay_now_button.css'
import { useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { formHelperTextClasses } from "@mui/material";


const ConfirmbookStyle= {
  backgroundColor: "black",
  boxshadow: "0 0 20px rgba(255, 255, 255, 0.4)",
  borderradius: "5px",
  padding: "30px",
  width: "35%",
  height: "maxContent",
  // margin: "0px 450px",
};


function loadScript(src) {
	return new Promise((resolve) => {
		const script = document.createElement('script')
		script.src = src
		script.onload = () => {
			resolve(true)
		}
		script.onerror = () => {
			resolve(false)
		}
		document.body.appendChild(script)
	})
}

const __DEV__ = document.domain === 'localhost'
var name = "Utsav"






function SlotSection(props){

let navigate = useNavigate();
  const [slotData, setSlotData] = useState([])

  const [bookedslot,setbookedslot] = useState([])
  const [userselectedslot, setuserselectedslot] = useState(0)
  const [halfhourprice, sethalfhourprice] = useState()

  const [selectslot, setselectslot] = useState([])
  const [PCname, setPCname] = useState([])
  const [TotalPrice, setTotalPrice] = useState(0)
 const [order_id,setorder_id] = useState("0")
 const [prebooking_id,setprebooking_id] = useState([])

 const RowSlotSize = 7;





 async function displayRazorpay() {
  const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

  if (!res) {
    alert('Razorpay SDK failed to load. Are you online?')
    return
  }


  var Url = window.API_PREFIX + 'user/issue_otp'
  fetch(Url, {
      method: 'POST',
      headers: {
          'Content-type': 'application/json',
      },
      body: JSON.stringify({ "Email":"2"}),
  }).then(resp => resp.json()).then(data => {
      if (data['status'] == "1") {
        const options = {
          key: __DEV__ ? 'rzp_test_QoYTwM9rHNMFDE' : 'PRODUCTION_KEY',
          currency: data.currency,
          amount: data.amount.toString(),
          order_id: "order_JzAIJCyro356Nu",
          name: 'Donation',
          description: 'Thank you for nothing. Please give us some money',
          image: K9logo,
          handler: function (response) {
            alert(response.razorpay_payment_id)
            alert(response.razorpay_order_id)
            alert(response.razorpay_signature)
          },
          prefill: {
            name,
            email: 'sdfdsjfh2@ndsfdf.com',
            phone_number: '9899999999'
          }
        }
        const paymentObject = new window.Razorpay(options)
        paymentObject.open()

      }
      else {



      }
  })


}


 function total_price(slot,pricing){
    var half_hour_price=pricing[0]
 
          if (slot==1){
           half_hour_price = pricing[0]}
       else if (slot>1 && slot<4){
           half_hour_price =  pricing[1]}
       else if (slot>=4){
         half_hour_price =  pricing[2]}
   sethalfhourprice(half_hour_price)
   return half_hour_price*slot
 
 
       }
 
function BookSlotFinal(slotLst)
       {var bookingslotTmp = []
        for(var seat =0;seat<slotLst.length;seat++)
      
        {
          for(var slotRow =0;slotRow<slotLst[seat]["Slot_id"].length;slotRow++)
          {
          for(var slot =0;slot<slotLst[seat]["Slot_id"][slotRow].length;slot++)
            
            
      
            if(slotLst[seat]["Slot_Status"][slotRow][slot]==2)
            {
             var seatid = slotLst[seat]["id"]
             var slotid = slotLst[seat]["Slot_id"][slotRow][slot]
      
            bookingslotTmp.push([seatid,slotid])
       
            }
        
        }
    
    
    
    }

        // setbookedslot([...bookingslotTmp])
        return bookingslotTmp;

      
        
        
       }
      


 function resetBookNow()
 {     setPCname([])
  
    setuserselectedslot(0)
    sethalfhourprice(0)
    setTotalPrice(0)
  
  }

function cleanslot(filled_slots) {

    for (var i = 0; i < filled_slots.length; ++i) {
      
    for (var j = 0; j < filled_slots[i].length; ++j) {

      if (filled_slots[i][j] == 2) {
        filled_slots[i][j] = 0

      }
    }
    }

    return filled_slots
  }



function breakList(arrayList)
{ var returnList = []
    
for (let i = 0; i < arrayList.length; i += RowSlotSize) {
    const chunk = arrayList.slice(i, i + RowSlotSize);
returnList.push(chunk)
}

return returnList
}


  useLayoutEffect(() => {
    var tmp = props.data
    
//  for(var eachSeat=0;eachSeat<tmp.length;eachSeat++)
//   {

//     tmp[eachSeat]["Time"] = breakList(tmp[eachSeat]["Time"])
//     tmp[eachSeat]["Slot_Status"] = breakList(tmp[eachSeat]["Slot_Status"])
//     tmp[eachSeat]["Slot_id"] = breakList(tmp[eachSeat]["Slot_id"])
    

 
//   }
    
    
setSlotData([...tmp])




 resetBookNow()

  },[props])


//   useEffect(() => {
//     // window.location.reload(false);
//   }, [props.Quantityslot])




    return(<><div className="d-flex">
    <div className="booking-slot-division">
        <div className="colors-division">
            <p className="colors-name"><span className="avail-color1"></span>Available</p>
            <p className="colors-name"><span className="avail-color3"></span>Selected</p>
            <p className="colors-name"><span className="avail-color4"></span>Already Booked</p>
        </div>

        {slotData.map((PCName, indexPC) => {




return (

        <div className ="mt-4">
            <h3 className="pc-names">{PCName["Name"]}</h3>


{PCName["Time"].map((eachSlotRow,SlotRowIndex)=>{
    return(
        <div className={PCName["Time"].length==RowSlotSize?"time-slote-division pt-3":"d-flex justify-content-start pt-3"}
        >
{
    eachSlotRow.map((eachSlotTime,SlotTimeIndex)=>{
   var Slot_Status_colour = slotData[indexPC]["Slot_Status"][SlotRowIndex][SlotTimeIndex]
        return( <div className="time-slote"
        style={{
            background: Slot_Status_colour== 1 ? "#B134DA" : Slot_Status_colour == -1 ? "#B134DA" : Slot_Status_colour == 2 ? "#4FCF70" :"white"

          }}
        onClick={(e) => {
            var tmp = slotData
 
            if (props.usertype == "U") {






              if (tmp[indexPC]["Slot_Status"][SlotRowIndex][SlotTimeIndex] === 0) {
                if (selectslot.length > 0) {
                  var lastSeatIndex = selectslot[0][0]
                  
                  cleanslot(tmp[lastSeatIndex]["Slot_Status"])
                }
                tmp[indexPC]["Slot_Status"] = cleanslot(tmp[indexPC]["Slot_Status"])
                
                var count = props.Quantityslot
                // var count =5
                setPCname(PCName["Name"])

                var k = 0
                var clickSlot = [SlotRowIndex,SlotTimeIndex]
                for (var i = clickSlot[0]; i < tmp[indexPC]["Slot_Status"].length; i++) {

                for (var d = clickSlot[1]; d < tmp[indexPC]["Slot_Status"][i].length; d++) {

                    clickSlot=[0,0]
                  if (tmp[indexPC]["Slot_Status"][i][d] === 0) {
                    tmp[indexPC]["Slot_Status"][i][d] = 2
                    
                    k++
                    var seat_slot = []
                    seat_slot.push([indexPC, i,d])
                    setselectslot([...seat_slot])
                    
                    setuserselectedslot(k)

                setTotalPrice(total_price(k,PCName["Price"]))

                    
                    count--;

                  

                  }
                  
                  if (count === 0) { break; }
                }
                if (count === 0) { break; }
            }




              }
              else if (tmp[indexPC]["Slot_Status"][SlotRowIndex][SlotTimeIndex] === 2) {
                resetBookNow()
           
                tmp[indexPC]["Slot_Status"] = cleanslot(tmp[indexPC]["Slot_Status"])
              }
            }


            setSlotData([...tmp])
            e.preventDefault()
          }}

        
        
        >{eachSlotTime}</div>)
    })
}
          
            </div>
    )
})
}


        </div>)})}
     
         
    </div>
    <div className="booking-division">
        <h4 className="price-card">Booking Price</h4>
        <div className="px-5 py-4">
        <div className="booking-pc-name-division">
                <p className="booking-pc">Selected Date:</p>
                <p className="booking-pc-name">{props.Date}</p>
            </div> 
            <div className="booking-pc-name-division">
                <p className="booking-pc">PC:</p>
                <p className="booking-pc-name">{PCname}</p>
            </div> 
            <div className="booking-pc-name-division">
                <p className="booking-pc">Total Slot:</p>
                <p className="booking-pc-name">{userselectedslot}</p>
            </div>
            <div className="booking-pc-name-division">
                <p className="booking-pc">Price Per Slot:</p>
                <p className="booking-pc-name">{halfhourprice}</p>
            </div>
            <div className="booking-pc-name-division">
                <p className="booking-pc">Total Price:</p>
                <p className="booking-pc-name">Rs {TotalPrice}</p>
            </div>
            {/* <a 
             className="btn book-now"
             
             
             
             >Book & Pay Now</a> */}

 

                    


                    <Popup
    trigger={
<div class="booknow-division"






                   
                   // onClick={(e)=>{
                   //   console.log(slotData)
                   //    var bookedSlot =  BookSlotFinal(slotData)
                   //    console.log(bookedSlot)
                   //    if(bookedSlot.length!=0){
                   //     var Url1 = window.API_PREFIX + 'booking/add_prebooking'
                   //     fetch(Url1, {
                   //         method: 'POST',
                   //         headers: {
                   //             'Content-type': 'application/json',
                   //         },
                   //         body: JSON.stringify({'Token': localStorage.getItem('Token'), "Seat_Slot":bookedSlot,"Date":props.Date,"price":TotalPrice}),
                   //     }).then(resp => resp.json()).then(data => {
                   //         console.log(data)
                   //         if (data['status'] == "1")
                   //         {
                   //           setorder_id(...data['order id'])
                   //           setprebooking_id(...data['booking_id'])
                     
                     
                   //           const options = {
                   //             key: __DEV__ ? 'rzp_test_QoYTwM9rHNMFDE' : 'PRODUCTION_KEY',
                   //             currency: 'INR',
                   //             amount: TotalPrice,
                   //             order_id: data['order id'],
                   //             name: data['Name'],
                   //             description: 'Thank you for nothing. Please give us some money',
                   //             image: K9logo,
                   //             handler: function (response) {
                   //               alert(response.razorpay_payment_id)
                   //               alert(response.razorpay_order_id)
                   //               alert(response.razorpay_signature)
                   //             },
                   //             prefill: {
                   //               name:"Name",
                   //               email: 'sdfdsjfh2@ndsfdf.com',
                   //               phone_number: '9899999999'
                   //             }
                   //           }
                   //           const paymentObject = new window.Razorpay(options)
                   //           paymentObject.open()
                     
                     
                     
                   //         }
                     
                     
                   //         else
                   //         {
                   //   console.warn("something went wrong")
                   //         }
                   //     console.log(data['order id'])
                       
                   //       })
                     
                   //   }}}


                   
                   
                   
                   style={{marginTop:'10px'}}>
                       <p type="button" class="btn book-now"
                       
                       >Book & Pay Now</p>
                   </div>
       
}
    modal
    contentStyle={ConfirmbookStyle}
  >
    {close => (
   <>
   <div className="confirmpopup text-white">Are you sure? Your slot will be booked and amount of {TotalPrice} INR will be deducted from wallet.</div>

                                    <div className="button_box_confirm_close">
                                        <div>
                                          {/* <div id="signup_btn"></div> */}
                                        <button type="button" className="toggle-btn confirm_botton"
                                        onClick={(e)=>{
                     
                                          var bookedSlot =  BookSlotFinal(slotData)
                                          
                                          if(bookedSlot.length!=0){
                                           var Url1 = window.API_PREFIX + 'booking/add_prebooking'
                                           fetch(Url1, {
                                               method: 'POST',
                                               headers: {
                                                   'Content-type': 'application/json',
                                               },
                                               body: JSON.stringify({'Token': localStorage.getItem('Token'), "Seat_Slot":bookedSlot,"Date":props.Date,"price":TotalPrice}),
                                           }).then(resp => resp.json()).then(data => {
                                              
                                               if (data['status'] == "1")
                                               {close();
                                                 navigate("/myprofile/mybookings")
                                                 window.location.reload()
                                         
                                         
                                         
                                         
                                               }
                                         
                                         else if(data['status'] == "-1")
                                         
                                         {
                                           toast.error('First verify you aadhar & Book again', {
                                             position: "top-center",
                                             autoClose: 4000,
                                             hideProgressBar: false,
                                             closeOnClick: true,
                                             pauseOnHover: true,
                                             draggable: true,
                                             progress: undefined,
                                             });
                                             close();
                                        
                                         }
                                               else
                                               {
                                                 toast.error('No Enough Wallet balance', {
                                                   position: "top-center",
                                                   autoClose: 4000,
                                                   hideProgressBar: false,
                                                   closeOnClick: true,
                                                   pauseOnHover: true,
                                                   draggable: true,
                                                   progress: undefined,
                                                   });
                                                   close();
                                    
                             
                                               }
                    
                          
                                           
                                             })
                                         
                                         }}}
                                        
                                        
                                        >Confirm</button>
                                        <button type="button" className="toggle-btn close_button" onClick={close}>Close</button>
                                        </div>
                                    </div>
                           
   </>
    )}
  </Popup>
                    <ToastContainer/>





        </div>
    </div>
    </div></>)
}
export default SlotSection





// onClick={(e)=>{
                     
//   var bookedSlot =  BookSlotFinal(slotData)
  
//   if(bookedSlot.length!=0){
//    var Url1 = window.API_PREFIX + 'booking/add_prebooking'
//    fetch(Url1, {
//        method: 'POST',
//        headers: {
//            'Content-type': 'application/json',
//        },
//        body: JSON.stringify({'Token': localStorage.getItem('Token'), "Seat_Slot":bookedSlot,"Date":props.Date,"price":TotalPrice}),
//    }).then(resp => resp.json()).then(data => {
      
//        if (data['status'] == "1")
//        {
//          navigate("/myprofile/mybookings")
 
 
 
 
//        }
 
//  else if(data['status'] == "-1")
 
//  {
//    toast.error('First verify you aadhar & Book again', {
//      position: "top-center",
//      autoClose: false,
//      hideProgressBar: true,
//      closeOnClick: true,
//      pauseOnHover: true,
//      draggable: true,
//      progress: undefined,
//      });

//  }
//        else
//        {
//          toast.error('No Enough Wallet balance', {
//            position: "top-center",
//            autoClose: false,
//            hideProgressBar: true,
//            closeOnClick: true,
//            pauseOnHover: true,
//            draggable: true,
//            progress: undefined,
//            });


//        }


   
//      })
 
//  }}}
