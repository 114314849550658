
import Login from "./Components/Login/Login";
import HeaderBeta from "./Layout/HeaderBeta";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import '../src/Components/Admin/K9AdminBase.css';
import '../src/Components/K9Base.css';
import Layout from "./Layout/Layout";
import { useState,useEffect } from "react";
import { Card } from "@mui/material";
import HomePage from "./Components/HomePage";
import AdminBookingPage from './Components/Admin/AdminComponent/Booking/BookingPage'
import BookingPage from './Components/BookingPage'
import MyProfileLayout from './Components/MyProfile/MyProfileLayout'


import Userbooking from './Components/MyProfile/Userbooking'
import Userprofile from './Components/MyProfile/Userprofile'
import Useraddmoney from './Components/MyProfile/Useraddmoney'
import Usertransaction from './Components/MyProfile/Usertransaction'
import Addcard from "./Components/MyProfile/Addcard"
import Addcarddetail from "./Components/MyProfile/Addcarddetail"
import Comingsoon from "./Components/Toolscomp/Comingsoon";
import Homepageone from "./Components/Toolscomp/Homepageone";
import Contact from "./Components/Toolscomp/Contact";
import Adminlayout from "./Components/Admin/Adminlayout"
import AdminUsers from "./Components/Admin/AdminComponent/AdminUsers"
import AdminUsersProfile from "./Components/Admin/AdminComponent/AdminUsersProfile"
import MyCoupon from "./Components/MyProfile/MyCoupon"




// window.API_PREFIX = "http://127.0.0.1:8000/"

window.API_PREFIX = "https://api.k9gaming.in/"

function App() {

  const [showMobileWarning, setShowMobileWarning] = useState(false)

useEffect(() => {

  if(window.innerWidth <= 977)
    setShowMobileWarning(true)
  else
  setShowMobileWarning(false)

}, [])
  return (
    <div>

      {showMobileWarning?
      <h1 style={{"color":"white"}}>Mobile View not available,Open site in Desktop view</h1>:
      <>
      <Routes 
      // path="/"
      >


          
          <Route exact path="/" element={<Layout/>} >
          {/* <Route exact path="/" element={<HomePage/>} /> */}
          <Route exact path="" element={<Homepageone/>} />   
          
          <Route exact path="booking" element={localStorage.getItem("Token")!=null?<BookingPage/>:<></>} />   
          <Route exact path="special-offer" element={<Comingsoon/>} />   
          <Route exact path="contactus" element={<Contact/>} />   
          <Route  path="myprofile" element={<MyProfileLayout/>} >
              <Route path="mybookings" element={<Userbooking/>} /> 
              <Route path="profile" element={<Userprofile/>} />  
              <Route path="mytransaction" element={<Usertransaction/>} />  
              <Route path="addmoney" element={<Useraddmoney/>} />  
              <Route path="addcard" element={ <Addcard />} />  
              <Route path="mycard" element={<Addcarddetail/>} />  
              <Route path="mycoupons" element={<MyCoupon/>} />  
             
          </Route>
              
          </Route>

        <Route  path="/administration" element={<Adminlayout/>} >

        <Route  path="allusers" element={<AdminUsers/>} />
        <Route  path="usersprofile/:UID" element={<AdminUsersProfile/>} />
        {/* <Route  path="susersprofile" element={<AdminUserprofile/>} /> */}
        <Route  path="booking" element={<AdminBookingPage/>} />

        {/* <Route  path="specification" element={<Navigate to="/administration/allproduct"/>} /> */}


          
          </Route>    
                      
          {/* <Route excat path='/courses' component={CourseFilter} /> */}
        
      



      </Routes>
  </>
      }


      {/* <HeaderBeta/> */}

    </div>
  );
}

export default App;
