// import "../../MyProfile/dashboard.css"
import '../AdminComponent/EditUserprofile.css'
import { useEffect, useLayoutEffect, useState } from "react"
import { useLocation } from 'react-router-dom';
// import right_tick from '../../media/download (3).png'
import Badge from 'react-bootstrap/Badge';




export default function EditUserprofile(props) {

    const { state } = useLocation();

    const [UserData, setUserData] = useState({});
    const [Name, setName] = useState("");
    const [NickName, setNickName] = useState("");
    const [Email, setEmail] = useState("");
    const [PhoneNumber, setPhoneNumber] = useState("");
    const [DOB, setDOB] = useState("");
    const [aadhar, setaadhar] = useState("");
    const [IsVerify, setIsVerify] = useState(false);
    const [CouponActive,setCouponActive] = useState(false);
    // const [validAdhar, setValidAdhar] = useState(true);

    useEffect(() => {

        var url = window.API_PREFIX + "user/dashboard";

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                "Token": localStorage.getItem("Token"),
                "UserId": props.UserId
            }),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            if (data['status'] == "1") {
                console.log(data["detail"]['UserName'])

                // setUserData({ ...data["detail"] })
                setName(data["detail"]['UserName'])
                setEmail(data["detail"]["Email"])
                setDOB(data["detail"]['DOB'])
                setPhoneNumber(data["detail"]["MobileNumber"])
                setIsVerify(data["detail"]['IsAadharVerify'])
                setNickName(data["detail"]["Nickname"])
                setaadhar(data["detail"]["Aadharcard_Num"])
                setCouponActive(data['detail']['CouponActive'])

            }
            else {

            }

        })

    }, [])



    return (
        <div class="admin-user-detail-main">
            <div class="admin-gaming-name-divition">
                <p class="admin-gaming-name">Name</p>
                <input type='text' className='admin-gaming-input-file' value={Name}
                    onChange={(e) => {
                        setName(e.target.value)
                    }}
                ></input>
                <p class="admin-user-edit-option-none"></p>
            </div>
            <div class="admin-gaming-name-divition">
                <p class="admin-gaming-name">Gaming Name</p>
                <input type='text' className='admin-gaming-input-file' value={NickName}
                    onChange={(e) => {
                        setNickName(e.target.value)
                    }}></input>

                {/* <p class="user-gaming-name">{UserData["Nickname"]}</p> */}
                <p class="admin-user-edit-option"></p>
            </div>
            <div class="admin-gaming-name-divition">
                <p class="admin-gaming-name">Email</p>
                <input type='text' className='admin-gaming-input-file' value={Email}
                    onChange={(e) => {
                        setEmail(e.target.value)
                    }}></input>
                {/* <p class="user-gaming-name">{UserData["Email"]}</p> */}
                <p class="admin-user-edit-option"></p>
            </div>
            <div class="admin-gaming-name-divition">
                <p class="admin-gaming-name">Phone Number</p>
                <input type='text' className='admin-gaming-input-file' value={PhoneNumber}
                    onChange={(e) => {
                        setPhoneNumber(e.target.value)
                    }}></input>
                {/* <p class="user-gaming-name">{UserData["MobileNumber"]}</p> */}
                <p class="admin-user-edit-option"></p>
            </div>
            <div class="admin-gaming-name-divition">
                <p class="admin-gaming-name">date of birth</p>
                <input type='date' className='admin-gaming-date-feild' value={DOB}
                    onChange={(e) => {
                        setDOB(e.target.value)
                    }}></input>
                {/* <p class="user-gaming-name">{UserData["DOB"]}</p> */}
                <p class="admin-user-edit-option-none"></p>
            </div>
            <div class="admin-gaming-name-divition">
                <p class="admin-gaming-name">adharcard</p>
                <input type='text' className='admin-gaming-input-file' value={aadhar}
                    onChange={(e) => {
                        setaadhar(e.target.value)
                    }}></input>
                {/* <p class="user-gaming-name">{UserData["Aadharcard_Num"]}</p> */}
                <div class="admin-user-edit-option-none">
                    {/* <input type='checkbox' checked={IsVerify}
                        onClick={(e) => {
                            setIsVerify(!IsVerify)
                        }}
                    ></input> */}
                
                    <i class={IsVerify ? "fa-solid fa-toggle-off admin_gaming_valid_adharcard" : "fa-solid fa-toggle-on admin_gaming_valid_adharcard"}
                        onClick={(e) => {
                            setIsVerify(!IsVerify)
                        }
                        }
                    ></i>

                </div>

                <div class="admin-user-edit-option-none">
       <br/>
                CouponActive
                    <i class={CouponActive ? "fa-solid fa-toggle-off admin_gaming_valid_adharcard" : "fa-solid fa-toggle-on admin_gaming_valid_adharcard"}
                        onClick={(e) => {
                            setCouponActive(!CouponActive)
                        }
                        }
                    ></i>
                    

                </div>

                {/* <p class="admin-user-edit-option-none"></p> */}
            </div>

            <div class="admin-gaming-name-divition">
                <p class="admin-gaming-name">Change Profile</p>
                <input
                    style={{ "cursor": "pointer" }}
                    type="file"
                    name="myImage"
                    onChange={(event) => {

                        var url = window.API_PREFIX + "user/edit_Photo";

                        // var uploadData = new FormData();
                        // uploadData.append("Token", localStorage.getItem("Token"));
                        // uploadData.append("Name", Name);
                        // uploadData.append("Email", Email);
                        // uploadData.append("PhoneNumber", PhoneNumber);
                        // uploadData.append("DOB",DOB );
                        // uploadData.append("aadhar",aadhar );
                        // uploadData.append("IsVerify",IsVerify );
                        // uploadData.append('NickName',NickName)
                        // uploadData.append("Pic", event.target.files[0]);
                        fetch(url, {
                            method: 'POST',
                            body: JSON.stringify({
                                "Token": localStorage.getItem("Token"),
                                "Pic": event.target.files[0],
                                "UserId": props.UserId

                            })
                        }).then(resp => resp.json()).then(data => {

                            if (data['status'] === "1") {
                                alert("Img Added")

                                // setresponseData({...data["detail"]})

                            }
                            else {
                                alert("Something went wrong")

                            }
                        })
                    }}
                />



            </div>
            <div class="admin-gaming-name-divition">
                <button class="admin-gaming-update-button"
                    onClick={(event) => {

                        var url = window.API_PREFIX + "user/edit_profile";



                        fetch(url, {
                            method: 'POST',
                            headers: {
                                'Content-type': 'application/json',
                            },
                            body: JSON.stringify(
                                {"Token": localStorage.getItem("Token"),
                            "Name": Name,
                            "Email": Email,
                            "PhoneNumber": PhoneNumber,
                            "DOB": DOB,
                            "aadhar": aadhar,
                            "IsVerify": IsVerify,
                            "CouponActive":CouponActive,
                            'NickName':NickName,
                            'UserId': props.UserId,
                        })

                        }).then(resp => resp.json()).then(data => {

                            if (data['status'] == "1") {
                                alert("Profile Updated")
                                window.location.reload()
                                // setresponseData({...data["detail"]})

                            }
                            else {
                                alert("Something went wrong")

                            }
                        })
                    }}
                >Update</button>
            </div>


        </div>
    )
}