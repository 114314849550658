import './Homepageone.css'
import React from 'react';
import Homeimg from '../../media/homepageimg.png';
import {useNavigate} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Homepageone() {
    let navigate = useNavigate()
    return (
        <>
            <div class="NewHomepage container text-center">
                <div class="row">
                    <div class="col home_page_button_title_section">
                        <div>
                            <p className='home_page_title_name'> <span className='home_page_title_name_quote'>"</span> The Only <span style={{color: '#80c904'}}>GameZone</span> In Town <span className='home_page_title_name_quote'>"</span> </p>
                            <div className='w-100 d-flex justify-content-center'><div className='home_book_now_button'
                            onClick={()=>
                            
                            {
                                if(localStorage.getItem('Token')!=null)
                                {navigate("/booking")}
                                else{  
                                    var LoginBox = document.getElementById('LoginBox')
                                    LoginBox.style.display = 'block'
                                    toast.error('Login to book slot', {
                                    position: "top-center",
                                    autoClose: 4000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    });}
                            
                             
                            }}
                            >Book Now</div></div>
                             <ToastContainer/>

                        </div>
                    </div>
                    <div class="col">
                        <img className='home_main_image' src={Homeimg} alt='img'></img>
                    </div>

                </div>
            </div>
        </>
    )
}
export default Homepageone;