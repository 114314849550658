
import {  Outlet } from "react-router-dom"
import AdminLogin from "./AdminComponent/AdminLogin" 
import Headers from "./Adminheader/Header"
import Sidebar from "./Adminheader/Sidebar"
// /AdminComponent/AdminLogin/AdminLogin"
// import { useStateValue } from "../StateProviders";
import { useEffect ,useState} from "react";

export default function Adminlayout() {

  const [ShowAdminLoginPop , setShowAdminLoginPop] = useState(false);
  useEffect(()=>{
    if(localStorage.getItem("Token")!==null)
    {
 setShowAdminLoginPop(true)
  }

    
    }
  )
  // const [{ CloseLogin }, dispatch] = useStateValue();
  
function LoginAdmin(Status){
  setShowAdminLoginPop(Status)
}

  return (<>
   
   {ShowAdminLoginPop?
     <>
     {/* <Headers/> */}
   <Sidebar/>
     {/* <Outlet/> */}
     </>:<AdminLogin LoginStatus={LoginAdmin}/>}</>

  );
}

