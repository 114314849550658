import {  Outlet } from "react-router-dom"
import './Header.css'
import { useNavigate } from 'react-router-dom'
function Sidebar() {
    let navigate = useNavigate()
    return (

        <>
            <div className='admin_header_sidebar_content_main_division'>


                <div className='admin_sidebar_division'>
                    <div className='admin_sidebar_home_icon_division'>
                        <i class="fa fa-home admin_sidebar_home_icon"></i>
                    </div>
                    <div className='admin_sidear_user_icon_division'
                        onClick={() => navigate("/administration/allusers")}
                    >
                        <i class="fa-solid fa-user-group admin_sidebar_user_icon"></i>
                    </div>

                    <div className='admin_sidear_user_icon_division'
                        onClick={() => navigate("/administration/booking")}
                    >
                        <i class="fa-solid fa-book admin_sidebar_user_icon"></i>
                    </div>
                </div>

                <div className='admin_header_main_division'>
                    <div className='admin_header_division'>
                        {/* <div class="d-flex admin_header_search_division" role="search">
                            <input class="form-control me-2 admin_header_search" type="search" placeholder="Search users" aria-label="Search" />
                            <i class="fa-solid fa-magnifying-glass admin_header_search_icon"></i>
                        </div> */}

                        <div className='admin_header_bell_icon_division'
                        onClick={()=>{
                            localStorage.clear()
                            navigate("/")
                        }} >
                            <i class="fa-solid fa-arrow-right-from-bracket admin_header_bell_icon"></i>
                        </div>
                        <div className='admin_header_setting_icon_division'>
                            <i class="fa-solid fa-gear admin_header_setting_icon"></i>
                        </div>
                    </div>
                    {/* write-content-here */}
                    <Outlet/>
                </div>

            </div>

        </>
    )
}
export default Sidebar