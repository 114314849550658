import './Usertransaction.css';

import { useEffect, useLayoutEffect, useState } from "react"
import { Link, useNavigate, NavLink, Outlet, useLocation } from 'react-router-dom';

import usePagination from "../Pagination";
import { Pagination } from '@mui/material';



const PaginationStyle = {
    backgroundColor: "black",
    boxshadow: "0 0 20px rgba(255, 255, 255, 0.4)",
    borderradius: "5px",
    padding: "30px",
    width: "35%",
    margin: "160px 450px"
};

export default function Usertransaction() {

    let navigate = useNavigate();
    const weekday = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    const month = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];


    //Pagination 

    let [page, setPage] = useState(1);
    const PER_PAGE = 5;

    const [TransactionLog, setTransactionLog] = useState([]);  //Our Data
    const count = Math.ceil(TransactionLog.length / PER_PAGE);
    const _DATA = usePagination(TransactionLog, PER_PAGE);



    const handleChange = (e, p) => {
        setPage(p);
        _DATA.jump(p);
    };
    // Pagination
    useEffect(() => {

        var url = window.API_PREFIX + "user/transaction_detail";

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify({ "Token": localStorage.getItem("Token") }),
        }).then(resp => resp.json()).then(data => {

            if (data['status'] == "1") {


                setTransactionLog([...data['Transaction Log']])

            }
            else {
            }
        })
    }, [])




    return (
        <>
            <div class="usertransaction_main_division">
                <div className='main-division'>
                    <div class="card-account">
                        <div class="card_name">Recent Transactions</div>
                        {/* <div class="search-icon"><i class="fa-solid fa-magnifying-glass"></i></div> */}
                        {/* <div class="filter-button"><button class="font-size">Filter</button></div> */}
                        {/* <div class="addmoney_division"
            onClick={(e)=>{
                navigate("/myprofile/addcard")
            }}>
                <a type="button" class="btn add_money"
                
                > Add Money</a>
            </div> */}
                    </div>
                    <div class="boder container-fuild">
                        <div class="total-balance row">
                            <div class="id col">ID</div>
                            <div class="date-time col">DATE</div>
                            <div class="credit col">AMOUNT PAID</div>
                            <div class="credit col">CREDIT</div>
                            <div class="debit col">DEBIT</div>
                            <div class="amount col">WALLET</div>
                            <div class="booking_type col">BOOKING TYPE</div>
                            <div class="remark col">REMARK</div>
                        </div>
                    </div>
                    {_DATA.currentData().map((eachTransaction, index) => {
                        const d = new Date(eachTransaction["Date"]);
                        let day = weekday[d.getDay()];
                        let monthname = month[d.getMonth()];
                        let datenumber = d.getDate();
                        return (
                            <div class="transaction_1">
                                <p class="transaction_details">{index + 1}</p>
                                <p class="transaction_details">{day}, {datenumber} {monthname}</p>
                                <p class="transaction_details"><i class="fa-solid fa-indian-rupee-sign"></i> {eachTransaction["AmountPaid"]}</p>
                                <p class="transaction_details"><i class="fa-solid fa-indian-rupee-sign"></i> {eachTransaction["Credit"]}</p>
                                <p class="transaction_details"><i class="fa-solid fa-indian-rupee-sign"></i> {eachTransaction["debit"]}</p>
                                <p class="transaction_details"><i class="fa-solid fa-indian-rupee-sign"></i> {eachTransaction["Amount"]}</p>
                                <p class="transaction_details">{eachTransaction["Booking type"]}</p>
                                <p class="transaction_details">{eachTransaction["Remark"]}</p>
                            </div>)
                    })}
                </div>

                <div className='pagination'>  <Pagination

                    count={count}
                    color="secondary"
                    size="large"
                    page={page}
                    variant="outlined"
                    shape="rounded"
                    onChange={handleChange}
                /></div>
            </div>


        </>)
}