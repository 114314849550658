import "./dashboard.css"
import {useEffect,useLayoutEffect,useState} from "react"




export default function Userbooking(props){


    const weekday = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    const month = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
  
  
const [Bookingcards,setBookingcards]= useState([]);
    useEffect(() => {

        var url = window.API_PREFIX + "booking/booking_cards";
    
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify({ "Token": localStorage.getItem("Token"),
            "UserId":props['UserId']
         }),
        }).then(resp => resp.json()).then(data => {
  
            if (data['status'] == "1") {
     

             setBookingcards([...data['len']])
            }
            else {
             alert("No booking")
            }
        })
      },[])



return(     <div class="active-booking-division flex-row flex-nowrap">

{Bookingcards.length===0?<div className="no_booking">No Booking</div>:
Bookingcards.map((item,index)=>{

const d = new Date(item["Date"]);
let day = weekday[d.getDay()];
let monthname = month[d.getMonth()];
let datenumber = d.getDate();
var Avail = item.Flag

    return(
<div class={Avail==1?"user-active-card":"user-past-card"}>
    <div class="my-booking d-flex">
        <div class={Avail==1?"user-active-division":"user-past-division"}>
            <p class="user-celender">{day}</p>
            <p class="user-celender">{datenumber}</p>
            <p class="user-celender">{monthname}</p>
        </div>
        <div class={Avail==1?"user-active-details":"user-past-details"}>
            <div class={Avail==1?"user-active-pcname":"user-past-pcname"}>{item.Seat}</div>
            <div class={Avail==1?"user-active-time-slot":"user-past-time-slot"}>{item["Time"]}</div>
        </div>
    </div>
    <div class={Avail==1?"user-active-booking pt-2 pb-2":"user-past-booking pt-2 pb-2"}>Booking Id <span>#{item["Booking_id"]}</span></div>
    <div class={Avail==1?"user-active-total-slot pt-3":"user-past-total-slot pt-3"}>
        
        <div class={Avail==1?"active-booking-pc-name-division":"past-booking-pc-name-division"}>
            <p class={Avail==1?"active-booking-pc":"past-booking-pc"}>Total Slots:</p>
            <p class={Avail==1?"active-booking-pc-name":"past-booking-pc-name"}>{item["Total_Slot"]}</p>
        </div>
        <div class={Avail==1?"active-booking-pc-name-division":"past-booking-pc-name-division"}>
            <p class={Avail==1?"active-booking-pc":"past-booking-pc"}>Price Per Slot:</p>
            <p class={Avail==1?"active-booking-pc-name":"past-booking-pc-name"}>{item["Price per Slot"]}</p>
        </div>
        <div class={Avail==1?"active-booking-pc-name-division":"past-booking-pc-name-division"}>
            <p class={Avail==1?"active-booking-pc":"past-booking-pc"}>Total Price:</p>
            <p class={Avail==1?"active-booking-pc-name":"past-booking-pc-name"}>Rs{item["Price"]}</p>
        </div>
        <div class={Avail==1?"active-booking-pc-name-division":"past-booking-pc-name-division"}>
            <p class={Avail==1?"active-booking-pc":"past-booking-pc"}>Paid By:</p>
            <div class={Avail==1?"active-booking-pc-name":"past-booking-pc-name"}>
                {item["paid by"]}
            </div>
        </div>
    </div>
</div>
    )
})
}





{/* 
<div class="user-past-card">
    <div class="my-booking d-flex">
        <div class="user-past-division">
            <p class="user-celender">sat</p>
            <p class="user-celender">16</p>
            <p class="user-celender">APR</p>
        </div>
        <div class="user-past-details">
            <div class="user-past-pcname">RTX3090</div>
            <div class="user-past-time-slot">11:00 - 12:00</div>
        </div>
    </div>
    <div class="user-past-booking pt-2 pb-2">Booking Id <span>#1234567</span></div>
    <div class="user-past-total-slot pt-3">
        
        <div class="past-booking-pc-name-division">
            <p class="past-booking-pc">Total Slots:</p>
            <p class="past-booking-pc-name">5</p>
        </div>
        <div class="past-booking-pc-name-division">
            <p class="past-booking-pc">Price Per Slot:</p>
            <p class="past-booking-pc-name">30</p>
        </div>
        <div class="past-booking-pc-name-division">
            <p class="past-booking-pc">Total Price:</p>
            <p class="past-booking-pc-name">Rs150</p>
        </div>
        <div class="past-booking-pc-name-division">
            <p class="past-booking-pc">Paid By:</p>
            <div class="past-booking-pc-name">
                Wallet
            </div>
        </div>
    </div>
</div>
<div class="user-past-card">
    <div class="my-booking d-flex">
        <div class="user-past-division">
            <p class="user-celender">sat</p>
            <p class="user-celender">16</p>
            <p class="user-celender">APR</p>
        </div>
        <div class="user-past-details">
            <div class="user-past-pcname">RTX3090</div>
            <div class="user-past-time-slot">11:00 - 12:00</div>
        </div>
    </div>
    <div class="user-past-booking pt-2 pb-2">Booking Id <span>#1234567</span></div>
    <div class="user-past-total-slot pt-3">
        
        <div class="past-booking-pc-name-division">
            <p class="past-booking-pc">Total Slots:</p>
            <p class="past-booking-pc-name">5</p>
        </div>
        <div class="past-booking-pc-name-division">
            <p class="past-booking-pc">Price Per Slot:</p>
            <p class="past-booking-pc-name">30</p>
        </div>
        <div class="past-booking-pc-name-division">
            <p class="past-booking-pc">Total Price:</p>
            <p class="past-booking-pc-name">Rs150</p>
        </div>
        <div class="past-booking-pc-name-division">
            <p class="past-booking-pc">Paid By:</p>
            <div class="past-booking-pc-name">
                Wallet
            </div>
        </div>
    </div>
</div>
<div class="user-past-card">
    <div class="my-booking d-flex">
        <div class="user-past-division">
            <p class="user-celender">sat</p>
            <p class="user-celender">16</p>
            <p class="user-celender">APR</p>
        </div>
        <div class="user-past-details">
            <div class="user-past-pcname">RTX3090</div>
            <div class="user-past-time-slot">11:00 - 12:00</div>
        </div>
    </div>
    <div class="user-past-booking pt-2 pb-2">Booking Id <span>#1234567</span></div>
    <div class="user-past-total-slot pt-3">
        
        <div class="past-booking-pc-name-division">
            <p class="past-booking-pc">Total Slots:</p>
            <p class="past-booking-pc-name">5</p>
        </div>
        <div class="past-booking-pc-name-division">
            <p class="past-booking-pc">Price Per Slot:</p>
            <p class="past-booking-pc-name">30</p>
        </div>
        <div class="past-booking-pc-name-division">
            <p class="past-booking-pc">Total Price:</p>
            <p class="past-booking-pc-name">Rs150</p>
        </div>
        <div class="past-booking-pc-name-division">
            <p class="past-booking-pc">Paid By:</p>
            <div class="past-booking-pc-name">
                Wallet
            </div>
        </div>
    </div> 
</div>*/}
</div>)
}