import { useEffect, useState } from "react"
import "./Addcard.css"
import { ToastContainer, toast } from 'react-toastify';

import { Navigate, useNavigate } from "react-router-dom";
function Addcard() {
let navigate = useNavigate()
    const [cardNumber, setcardNumber] = useState();
    const [secret, setsecret] = useState();
    var url = window.API_PREFIX + "user/mycards";
  useEffect(()=>{  
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
        },
        body: JSON.stringify({ "Token": localStorage.getItem("Token") }),
    }).then(resp => resp.json()).then(data => {
        if (data['status'] == "1") {
            navigate("/myprofile/mycard")

        }
        else if (data['status'] == "0")  {
        
        
        }
        
    })
  },[])

    return (
        <div>
            <div className="user_add_card_main">
                <h4 className="user_add_card_heading">Card Information</h4>
                <div className="user_add_card_division">
                    <div class="form-group user_add_card_number_division">
                        <label className="user_add_card_number_lable w-100" for="formGroupExampleInput">Card Number</label>
                        <input type="text" class="form-control user_add_card_number_input w-100" id="formGroupExampleInput" placeholder="16 Digit No of your K9 Gaming Loyalty Card " maxLength={16} pattern="[+-]?\d+(?:[.,]\d+)?" autoComplete="off"
                            onChange={(e) => {
                                setcardNumber(e.target.value)
                            }}
                        />
                    </div>
                    <div class="form-group">
                        <label className="user_add_card_security_card" for="formGroupExampleInput2">Security Code</label>
                        <input type="text" class="form-control w-25" id="formGroupExampleInput2" placeholder="4 Digit Code" maxLength={4} autoComplete="off"
                            onChange={(e) => {
                                setsecret(e.target.value)
                            }}
                        />
                    </div>
                    <div className="d-flex justify-content-center w-100 ">
                        <div className="user_add_card_button"
                            onClick={() => {
                                var Url = window.API_PREFIX + 'user/cardbalance'
                                fetch(Url, {
                                    method: 'POST',
                                    headers: {
                                        'Content-type': 'application/json',
                                    },
                                    body: JSON.stringify(
                                        {
                                             "Token": localStorage.getItem("Token"),
                                            "CardNumber": cardNumber,
                                            "Key": secret
                                        }
                                    ),
                                }).then(resp => resp.json()).then(data => {
                                    if (data['status'] == "1" || data['status']=='2') {
                                        toast.info("Balance successfully added", {
                                            position: "top-center",
                                            autoClose: false,
                                            hideProgressBar: true,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                            });   
                                         
                                        navigate("/myprofile/mycard")
                                        window.location.reload(true)
                                       



                                    }
                                    else 
                                    {
                                        toast.error(data["message"], {
                                            position: "top-center",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                            });   

                                    }
                                }
                                )
                            }}
                        >Register Card</div>
                        <ToastContainer />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Addcard