import "./AdminLogin.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
function AdminLogin(props) {
  let navigate = useNavigate();
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");

  const [Inputtype, setInputtype] = useState("password");
  const [ButtonName, setButtonName] = useState(<i class="fa-solid fa-eye"></i>);
  return (
    <>
      <div className="container">
        <div className="adminlogin-login-right-side-division ">
          <form className="input-group justify-content-center ">
            <div>
              <h2 className="adminlogin-login-text">Login</h2>
            </div>

            <div className="mb-3 d-flex">
              <div className="adminlogin-login-icon me-2">
                <i className="fa-solid fa-user"></i>
              </div>
              <input
                type="email"
                className="adminloginformcontrol"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>

            <div className="mb-3 d-flex">
              <div className="adminlogin-login-icon me-2">
                <i className="fa-solid fa-lock text-#97F704"></i>
              </div>
              <div className="admin_login_password_field_division">
              <div class="admin_login_email_field_division1 form-floating">
              <input
                type={Inputtype}
                className="adminloginformcontrol"
                id="exampleInputPassword1"
                autoComplete="off"
                placeholder="Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              </div>
              <div className="admin_login_password_eyes_icon">
                <i
                  className=" eye_icon"
                  onClick={(e) => {
                    if (Inputtype == "password") {
                      setInputtype("text");
                      setButtonName(<i class="fa-solid fa-eye-slash"></i>);
                    } else {
                      setInputtype("password");
                      setButtonName(<i class="fa-solid fa-eye"></i>);
                    }

                    e.preventDefault();
                  }}
                >
                  {ButtonName}
                </i>
              </div>
              </div>
            </div>

            <div className="mb-3 d-flex w-100">
              <div className="w-50 d-flex justify-content-end">
                <a className="adminlogin-login-forget-pss" href="#">
                  Forget Password?
                </a>
              </div>
            </div>

            <div>
              <button
                type="button"
                className="adminlogin-login-succ-individual"
                onClick={() => {
                  var Url = window.API_PREFIX + "admin/adminlogin";
                  fetch(Url, {
                    method: "POST",
                    headers: {
                      "Content-type": "application/json",
                    },
                    body: JSON.stringify({ Email: Email, Password: Password }),
                  })
                    .then((resp) => resp.json())
                    .then((data) => {
                      console.log(data);
                      if (data["status"] == "1") {
                        localStorage.setItem("Token", data["Token"]);
                        props.LoginStatus(true);
                        navigate("allusers");
                      } else {
                        alert("Invalid ID/Password");
                      }
                    });
                }}
              >
                LogIn
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AdminLogin;
