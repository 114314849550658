import ProfileImg from '../../media/ProfilePic.jpeg'
import "./dashboard.css"
import { Link, useNavigate, NavLink, Outlet,useLocation } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import {useEffect,useLayoutEffect,useState} from "react"
import { Card } from '@mui/material';


export default function MyProfileHeader()
{ let navigate = useNavigate();

    const [UserData,setUserData]= useState({});
    const [subWallet,setsubWallet] = useState(false);
    const [CardStatus,setCardStatus] = useState(false)


    // const { UserImg,state,UserName } = useLocation();

    useEffect(() => {

        var url = window.API_PREFIX + "user/dashboard";
    
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify({ "Token": localStorage.getItem("Token") }),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
           
            if (data['status'] == "1") {
                
setUserData({...data["detail"]})

            }
            else {
            
            }
            
        })

        var url = window.API_PREFIX + "user/mycards";
    
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify({ "Token": localStorage.getItem("Token") }),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            if (data['status'] == "1") {
                // if(data['UserCardList'].length!==0){
                //     setCardStatus(true)}
                // else{

                // setCardStatus(false)
                // }
                setCardStatus(true)

            }
            else if (data['status'] == "0")  {
                setCardStatus(false)
            
            }
            
        })
      },[])


return(<body>
    <div class="container user-offer">

    </div>
    <div class="user-photo-section">
        <div>
           
            <div className='user-photo-division'><img src={UserData["UserImg"]} class="user-photo" alt=""/></div>
            <h3 class="user-name">{UserData["Nickname"]} <span class="user-position">{UserData["UserName"]}</span></h3>
        </div>
    </div>

 



    <div class="user-info">
        <div class="user-booking-wallet">
        <p className='mb-0'
        onClick={(e)=>{setsubWallet(false)}}
        >
                    <NavLink
                style={{textDecoration: 'none'}}
                    to="profile"
                    className={({ isActive }) => (isActive ? "user-profile-data-active" : "user-profile-data")}>
                    
                   User Profile
                    </NavLink>
                    </p>



    { !CardStatus? <p className='mb-0'
        onClick={(e)=>{setsubWallet(false)}}
        >
                    <NavLink
                style={{textDecoration: 'none'}}
                    to="addcard"
                    className={({ isActive }) => (isActive ? "user-profile-data-active" : "user-profile-data")}>
                    
                    Register a card
                    </NavLink>
                    </p>

:
                    <p className='mb-0'
        onClick={(e)=>{setsubWallet(false)}}
        >
                    <NavLink
                style={{textDecoration: 'none'}}
                    to="mycard"
                    className={({ isActive }) => (isActive ? "user-profile-data-active" : "user-profile-data")}>
                    
                    My Cards
                    </NavLink>
                    </p>
}

                    
                    {/* <p className='user-profile-data'>
                     card
                    </p> */}

                    <p className='mb-0'
                      onClick={(e)=>{setsubWallet(false)}} >
                    <NavLink
                style={{textDecoration: 'none'}}
                    to="mybookings"
                    className={({ isActive }) => (isActive ? "user-profile-data-active" : "user-profile-data")}>
                    
                    My Booking
                    </NavLink>
                    </p>

                    <p className='mb-0'
                      onClick={(e)=>{setsubWallet(false)}} >
                    <NavLink
                style={{textDecoration: 'none'}}
                    to="mycoupons"
                    className={({ isActive }) => (isActive ? "user-profile-data-active" : "user-profile-data")}>
                    
                    My Coupons
                    </NavLink>
                    </p>

                <p
                onClick={(e)=>{
                    var flag = subWallet
                    setsubWallet(!flag)
                navigate('mytransaction')
                   
                }}
                        className={subWallet?'user-profile-data-active':"user-profile-data"}
                        >Wallet</p>



{ subWallet?<>
          <p className='mb-0'>
                    <NavLink
                style={{textDecoration: 'none'}}
                    to="mytransaction"
                    className={({ isActive }) => (isActive ? "wallet-active" : "wallet")}>
                    
                    My Transaction
                    </NavLink>
                    </p>    

                    {/* <p className='mb-0'>
                    <NavLink
                style={{textDecoration: 'none'}}
                    to={{
                        pathname:"addmoney",
                     
                             
                        }}
                    className={({ isActive }) => (isActive ? "wallet-active" : "wallet")}>
                    
                    Add money
                    </NavLink>
                    </p>  */}
                      </> :null
}
              


              <p className="user-profile-data"
              onClick={(e)=>{
               
                localStorage.clear()
                navigate("/");
                e.preventDefault();
              }}>Log out</p>
          
        </div>
       
        <Outlet/>
    </div> 
    </body>
)
}