

import './home.css';

// import scrollImg3 from '../media/img3.jpg'
// import scrollImg2 from '../media/img2.jpg'
// import scrollImg1 from '../media/img1.jpg'
import Header from '../Layout/Header';

import 'swiper/css';


// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useNavigate } from 'react-router-dom';
import {useState,useEffect} from 'react'



function HomePage() {


let navigate = useNavigate()
const [showSign, setshowSign] = useState(true)


useEffect(() => {

  if (localStorage.getItem('Token') === null) {
      setshowSign(true)

  }
  else { setshowSign(false) }})

  // const swiper = new Swiper('.swiper', {
  //     autoplay: {
  //       delay: 5000,
  //       disableOnInteraction: false,
  //     },
  //     loop: true,

  //     pagination: {
  //       el: '.swiper-pagination',
  //       clickable: true,
  //     },

  //     navigation: {
  //       nextEl: '.swiper-button-next',
  //       prevEl: '.swiper-button-prev',
  //     },

  //   });
  return (

    <body style={{ backgroundColor: "#191F38" }}>

      <div className="container p-0">

        <Swiper
          // install Swiper modules
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          spaceBetween={50}
          slidesPerView={1}
          navigation
          loop={true}
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          // onSlideChange={() => console.log('slide change')}
          autoplay={{ delay: 2000 }}
        >
          {/* <SwiperSlide> <div className="swiper-slide"><img src={scrollImg1} /></div></SwiperSlide>
          <SwiperSlide> <div className="swiper-slide"><img src={scrollImg2} /></div></SwiperSlide>
          <SwiperSlide> <div className="swiper-slide"><img src={scrollImg3} /></div></SwiperSlide> */}

        </Swiper>

      </div>

      <script src="https://unpkg.com/swiper@8/swiper-bundle.min.js"></script>


      {/* <!-- slider-end -->

  <!-- cards-start --> */}
      <div className='home_book_cards'>
        <div className='d-flex justify-content-center'>
          <div className='home_book_now_button'
          onClick={(e)=>{navigate(showSign?"/":"/booking")}}
          >Book Now</div>
        </div>
        {/* <div className="card-main-division">

          <div className="card-division">

          </div>
          <div className="card-division">

          </div>
          <div className="card-division">

          </div>
          <div className="card-division">

          </div>
        </div> */}
      </div>

      {/* <!-- cards-end -->

  <!-- testimonials-start --> */}
      {/* <div className="testimonial-division">
        <img className="testimonial" src="igor-karimov-9AmKnNZw3GA-unsplash.jpg" alt="Avatar" height="80px" style={{ width: "80px" }} />
        <p><span className="testimonial-span">Chris Fox.</span> CEO at Mighty Schools.</p>
        <p>John Doe saved us from a web disaster.</p>
      </div> */}
    </body>
  )
}
export default HomePage