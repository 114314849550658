import React from 'react';
import footerK9img from "../media/K9 Gaming Logo Website.png"
import './Footer.css';
import { useNavigate } from "react-router-dom"

function Footer() {
    let navigate = useNavigate();
    return (
        <>
            <div>
                <div className='footer_main_division'>
                    <div className='footer_main_sub_division'>
                        <div className='footer_main_about_us_division'>
                            <div className='footer_sub_title'>About Us</div>
                            <img className='footer_logo_img'
                                onClick={() => {
                                    navigate("/")
                                }}
                                src={footerK9img}></img>
                        </div>

                        <div className='footer_main_usefull_links_division'>
                            <div className='footer_sub_title'>Usefull Links</div>
                            <div className='footer_usefull'>
                                <div className='footer_usefull_name'
                                    onClick={() => { navigate("/") }}
                                ><i class="fa-solid fa-house me-2"></i> Home</div>
                                <div className='footer_usefull_name'
                                    onClick={() => {
                                        if (localStorage.getItem('Token') != null) { navigate("/booking") }
                                        else {
                                            var LoginBox = document.getElementById('LoginBox')
                                            LoginBox.style.display = 'block'
                                        }

                                    }}
                                ><i class="fa-solid fa-book me-2"></i> Booking</div>
                                <div className='footer_usefull_name'
                                    onClick={() => { navigate("/special-offer") }}
                                ><i class="fa fa-percent me-2" aria-hidden="true"></i>
                                    Special Offers</div>
                                <div className='footer_usefull_name'
                                    onClick={() => { navigate("/contactus") }}
                                ><i class="fa-solid fa-phone me-2"></i> Contact us</div>
                            </div>
                        </div>

                        <div className='footer_contact_us_division'>
                            <div className='footer_sub_title'>Contact Us</div>

                            <div className='footer_contact_us_sub_division'>
                                <div className='footer_main_address_field'>
                                    <div className='footer_location_icon'><i class="fa-solid fa-location-dot"></i></div>
                                    <div className='footer_address_title'>Address:</div>
                                </div>
                                <div className='footer_address'><a href='https://goo.gl/maps/oKNstrnozYVDV9fG6' target="_blank" style={{ color: "white", textDecoration: "none" }}>FF-25, Sun Rise Shops & Flats, Waghodia Rd, near Vrundavan Crossing, Vadodara, 390019</a></div>

                                <div className='footer_main_address_field'>
                                    <div className='footer_location_icon'><i class="fa-solid fa-mobile-screen-button"></i></div>
                                    <div className='footer_address_title'>Mobile:</div>
                                </div>
                                <div className='footer_address'> <a href='tel:(+91) 7383977771' style={{ color: "white", textDecoration: "none" }}> (+91) 7383977771</a></div>
                                <div className='footer_address'> <a href='tel:(+91) 8866656093' style={{ color: "white", textDecoration: "none" }}>(+91) 8866656093</a> </div>

                                <div className='footer_main_address_field'>
                                    <div className='footer_location_icon'><i class="fa-solid fa-envelope"></i></div>
                                    <div className='footer_address_title'>Email:</div>
                                </div>
                                <div className='footer_email_address'> <a href='mailto:contact@k9gaming.in' style={{ color: "white", textDecoration: "none" }}>contact@k9gaming.in</a></div>
                            </div>
                        </div>

                        <div className='footer_map_visit_division ml-4'>
                            <div className='footer_sub_title'>Give Us a Visit</div>
                            <div className='footer_map'>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3691.4690908432462!2d73.24818069999999!3d22.298092299999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395fc51a7e16037d%3A0xe37e5c6e3a55cbf8!2sK9Gaming.in!5e0!3m2!1sen!2sin!4v1670486607059!5m2!1sen!2sin" width="360" height="260" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

                                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3691.4690908432462!2d73.24818069999999!3d22.298092299999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395fc51a7e16037d%3A0xe37e5c6e3a55cbf8!2sK9Gaming.in!5e0!3m2!1sen!2sin!4v1670486382331!5m2!1sen!2sin" width="400" height="300" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                            </div>
                        </div>
                    </div>
                    <div className='footer_copyright_division'>
                        <div className='footer_copyright'>COPYRIGHT <i class="fa-regular fa-copyright"></i> 2022 K9 GAMING | DEVELOPED BY NYSHA SOLUTIONS</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer;
