import './Comingsoon.css'
import comingsoonimage from '../../media/comingsoon.jpg'
function Comingsoon(){
    return(
        <>
        <div>
        <img className='comingsoon_image' src={comingsoonimage}></img>
        </div>
        </>
    )
}
export default Comingsoon;