import './AdminUsersProfile.css';
import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { StarOutlined, EyeFilled, SettingFilled } from '@ant-design/icons';
import { Button, Table } from 'antd';
import 'antd/dist/antd.css';
import Popup from "reactjs-popup";


import Userbooking from '../../MyProfile/Userbooking'
import EditUserprofile from './EditUserprofile'




const AddMoneyStyle = {
    backgroundColor: "grey",
    boxshadow: "0 0 20px rgba(255, 255, 255, 0.4)",
    borderradius: "5px",
    padding: "30px",
    width: "35%",
    margin: "160px 450px",
    height: "max-content"

};


// import adminusersprofileimg from '../../image/profileimage.jpg';

function AdminUsersProfile() {

    const [AddMoneyFlag, setAddMoneyFlag] = useState(false)
    const [AddCoupons, setAddCoupons] = useState(false)
    const [Coupons, setCoupons] = useState("")
    const [Money, setMoney] = useState("")
    const [CreditDebit, setCreditDebit] = useState("CREDIT")
    const [Description, setDescription] = useState('')
    const [AmountPaid, setAmountPaid] = useState("")
    const [PaymentType, setPaymentType] = useState("CASH")
    const Currentdate = new Date().toJSON().slice(0, 10);
    const [Img, setImg] = useState("");
    const [showImg, setshowImg] = useState();
    const [Uniquecode, setUniquecode] = useState("");
    const [Desc, setDesc] = useState("");
    const [ExpiryDate, setExpiryDate] = useState("");
    const [coupon,setcoupon] = useState("");
    





    const Transactioncolumns = [
        {
            title: 'Date',
            dataIndex: 'Date',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "14px", textAlign: "center", width: "250px", color: "#F7E166" }
                    },
                    children: <div>{text}</div>
                }
            }
        },
        {
            title: 'AmountPaid',
            dataIndex: 'AmountPaid',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "14px", textAlign: "center" }
                    },
                    children: <div>{text}</div>
                }
            }
        },
        {
            title: 'Credit',
            dataIndex: 'Credit',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "14px", textAlign: "center" }
                    },
                    children: <div>{text}</div>
                }
            }
        },
        {
            title: 'Debit',
            dataIndex: 'debit',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "14px", textAlign: "center", width: "250px" }
                    },
                    children: <div>{text}</div>
                }
            }
        },
        {
            title: 'Amount',
            dataIndex: 'Amount',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "14px", textAlign: "center", width: "250px" }
                    },
                    children: <div>{text}</div>
                }
            }
        }, {
            title: 'Booking type',
            dataIndex: 'Booking type',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "14px", textAlign: "center", width: "250px" }
                    },
                    children: <div>{text}</div>
                }
            }
        },
        {
            title: 'Remark',
            dataIndex: 'Remark',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "14px", textAlign: "center", width: "250px" }
                    },
                    children: <div>{text}</div>
                }
            }
        },
    ];
    
    const Coupocolumns = [
        {
            title: 'CouponCode',
            dataIndex: 'CouponCode',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "14px", textAlign: "center", width: "250px", color: "#F7E166" }
                    },
                    children: <div>{text}</div>
                }
            }
        },
        {
            title: 'Description',
            dataIndex: 'Description',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "14px", textAlign: "center", width: "250px", color: "#F7E166" }
                    },
                    children: <div>{text}</div>
                }
            }
        },
        {
            title: 'AddOn',
            dataIndex: 'AddOn',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "14px", textAlign: "center", width: "250px", color: "#F7E166" }
                    },
                    children: <div>{text}</div>
                }
            }
        },
        {
            title: 'ExpiryDays',
            dataIndex: 'ExpiryDays',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "14px", textAlign: "center" }
                    },
                    children: <div>{text}</div>
                }
            }
        },
        {
            title: 'Expiry Status',
            dataIndex: 'Expiry_Status',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "14px", textAlign: "center" }
                    },
                    children: <div>{text}</div>
                }
            }
        },
        {
            title: 'Delete',
            dataIndex: 'Delete',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "14px", textAlign: "center", width: "250px" }
                    },
                    children: <div>{text}</div>
                }
            }
        },
    ];
    const Bookingcolumns = [
        {
            title: 'DATE',
            dataIndex: 'DATE',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "14px", textAlign: "center", width: "250px", color: "#F7E166" }
                    },
                    children: <div>{text}</div>
                }
            }
        },
        {
            title: 'TIME',
            dataIndex: 'TIME',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "14px", textAlign: "center" }
                    },
                    children: <div>{text}</div>
                }
            }
        },
        {
            title: 'REMAINING BALANCE',
            dataIndex: 'REMAININGBALANCE',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "14px", textAlign: "center", width: "250px" }
                    },
                    children: <div>{text}</div>
                }
            }
        },
    ];

    let navigate = useNavigate()
    const params = useParams()
    const { UID } = params

    const [SUserData, setSUserData] = useState({})
    useEffect(() => {
        var url = window.API_PREFIX + "admin/single_user_detail";

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                "Token": localStorage.getItem("Token"),
                "UserId": UID,
            }),

        }).then(resp => resp.json()).then(data => {
            console.log(data)
            if (data['status'] == "1") {
                setSUserData({ ...data['UserData'] })
                setTransactionData([...data['TransactionData']])

            }
        })

        var url2 = window.API_PREFIX + "booking/allcouponuserwise";

        fetch(url2, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                "Token": localStorage.getItem("Token"),
                "UID": UID,
            }),

        }).then(resp => resp.json()).then(data1 => {
            console.log(data1)
            if (data1['status'] == "1") {
                setCouponData([...data1['Coupons'] ])
    

            }
        })
    }, [])

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);

    const [TransactionData, setTransactionData] = useState([]);
    const [BookingData, setBookingData] = useState([]);
    const [CouponData,setCouponData] = useState([]);

    const [TransactionFlag, setTransactionFlag] = useState(false)
    const [BookingFlag, setBookingFlag] = useState(false);
    const [ViewprofileFlag, setViewprofileFlag] = useState(true);
    const [CouponFlag, setCouponFlag] = useState(false);


    return (
        <>
            <div>
                <div className='adminusersprofile_main_division'>
                    <div className='adminuserprofile_title_name'>USERS</div>

                    <div className='adminusersprofile_sub_division'>
                        {/* <div className='adminusersprofile_close_button_division'>
                            <div className='adminusersprofile_close_button'><i class="fa-solid fa-xmark"></i></div>
                        </div> */}

                        <div className='adminusersprofile_profile_main_division'>
                            <div className='adminusersprofile_picture_division'>
                                <img className='adminusersprofile_profile_img' src={SUserData['UserImg']} />
                            </div>

                            <div className='adminusersprofile_user_profile_name'>
                                <div className='adminusersprofile_user_name'></div>
                                <div className='adminusersprofile_user_id'>{SUserData['USERNAME']}</div>
                                <div className='adminusersprofile_user_email'>{SUserData['EMAIL']}</div>
                                <div className='adminusersprofile_user_email'>{SUserData['MOBILENUMBER']}</div>
                                <div className='adminusersprofile_user_email'>Balance : {SUserData['REMAININGBALANCE']}</div>
                            </div>
                            <div className='adminusersprofile_MoneyCoupon_main_division'>
                                <button type="button" className='adminusersprofile-btn-add-money-popup'
                                    onClick={() => {
                                        setAddMoneyFlag(true)
                                    }}
                                >ADD MONEY</button>
                                <button type="button" className='adminusersprofile-btn-add-money-popup'
                                    onClick={() => {
                                        setAddCoupons(true)
                                    }}
                                >ADD Coupons</button>
                                      <div className='addcoupon_popup_label_text'>
                                        <div className='addcoupon_popup_amount_title'>Unique Random Code</div>
                                        <input type="text" class="addCouponformControl_popup" placeholder=""
                                        onChange={(e)=>{
                                        setCoupons(e.target.value)   
                                    
                                        
                                        }}  
                                        ></input>
                                    </div>
                                <button type="button" className='adminusersprofile-btn-add-money-popup'
                                    onClick={() => {
                                        var Url = window.API_PREFIX + 'booking/applycoupon'
                                        fetch(Url, {
                                            method: 'POST',
                                            headers: {
                                                'Content-type': 'application/json',
                                            },
                                            body: JSON.stringify(
                                                {
                                                    Token: localStorage.getItem('Token'),
                                                    UID:UID,
                                                    Code:Coupons
                                                    
                                                 
                                
                                
                                
                                                }
                                            ),
                                        }).then(resp => resp.json()).then(data => {
                                            console.log(data)
                                            if (data['status'] === "1") {

                                                alert('Coupon --'+Coupons+' Used successfully')
                                            }
                                            else
                                            {
                                                alert(data['message'])
                                            }
                                        
                                        })





                                    }}
                                >Check Coupon</button>
                            </div>
                            <div className='adminusersprofile_button_main_division'>

                                <div className='adminusersprofile_view_profile_button_division'>
                                    <button type="button" class={ViewprofileFlag ? "adminusersprofile-btn-view-profile" : "adminusersprofile-btn-view-profile-active"}
                                        onClick={() => {
                                            setBookingFlag(false)
                                            setTransactionFlag(false)
                                            setViewprofileFlag(true)
                                            setCouponFlag(false) 
                                        }}
                                    >VIEW PROFILE</button>
                                </div>
                                <div className='adminusersprofile_view_profile_button_division'>
                                    <button type="button" class={TransactionFlag ? "adminusersprofile-btn-view-profile" : "adminusersprofile-btn-view-profile-active"}
                                        onClick={() => {
                                            setBookingFlag(false)
                                            setTransactionFlag(true)
                                            setViewprofileFlag(false)
                                            setCouponFlag(false) 

                                        }}>VIEW TRANSACTION</button>
                                </div>
                                <div className='adminusersprofile_view_profile_button_division'>
                                    <button type="button" class={BookingFlag ? "adminusersprofile-btn-view-profile" : "adminusersprofile-btn-view-profile-active"}
                                        onClick={() => {
                                            setBookingFlag(true)
                                            setTransactionFlag(false)
                                            setViewprofileFlag(false)
                                            setCouponFlag(false) 

                                        }}>VIEW BOOKING</button>
                                </div>
                                <div className='adminusersprofile_view_profile_button_division'>
                                    <button type="button" class={CouponFlag ? "adminusersprofile-btn-view-profile" : "adminusersprofile-btn-view-profile-active"}
                                        onClick={() => {
                                            setBookingFlag(false)
                                            setTransactionFlag(false)
                                            setViewprofileFlag(false)
                                            setCouponFlag(true) 

                                        }}>VIEW Coupons</button>
                                </div>
                                
                                </div>
                        </div>
                        {ViewprofileFlag ? <EditUserprofile UserId={UID} /> : <></>}

                        {BookingFlag ? <Userbooking UserId={UID} /> : <></>}



                        {TransactionFlag ? <>

                            <div className='adminusers_searchfield_division'>
                                {/* <button
                                    onClick={() => {
                                        setAddMoneyFlag(true)
                                    }}
                                >ADD MONEY</button> */}
                                {/* <div className='adminusers_textfield'>
                                    <input type="text" class="adminusersformControl" placeholder="Search Amount"></input>
                                </div> */}
                                <input type='date' className='adminusers_date_icon'
                                    value={Currentdate}
                                >
                                    {/* <i class="fa-solid fa-angle-down p-2"></i> */}
                                </input>
                            </div>



                            <div className='adminusers_table_main_division'>
                                <div
                                    style={{
                                        marginBottom: 16,
                                    }}>
                                    <span className='all_products_data_table'
                                        style={{
                                            marginLeft: 8,
                                        }}
                                    >
                                        {/* {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''} */}
                                    </span>
                                </div>
                                <div className='w-100 allproduct_table_number'>
                                    <Table className='all_products_tables'
                                        //  rowSelection={rowSelection}
                                        columns={Transactioncolumns} dataSource={TransactionData} />
                                </div>
                            </div>
                        </> : <></>}

                        {CouponFlag ? <>

                        <div className='adminusers_searchfield_division'>
                            {/* <button
                                onClick={() => {
                                    setAddMoneyFlag(true)
                                }}
                            >ADD MONEY</button> */}
                            {/* <div className='adminusers_textfield'>
                                <input type="text" class="adminusersformControl" placeholder="Search Amount"></input>
                            </div> */}
                        </div>



<div className='adminusers_table_main_division'>
    <div
        style={{
            marginBottom: 16,
        }}>
        <span className='all_products_data_table'
            style={{
                marginLeft: 8,
            }}
        >
            {/* {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''} */}
        </span>
    </div>
    <div className='w-100 allproduct_table_number'>
        <Table className='all_products_tables'
            //  rowSelection={rowSelection}
            columns={Coupocolumns} dataSource={CouponData} />
    </div>
</div>
</> : <></>}
                        <Popup
                            modal

                            contentStyle={AddMoneyStyle}
                            open={AddMoneyFlag}
                            closeOnDocumentClick={true}
                            onClose={() => { setAddMoneyFlag(false) }}
                        >
                            <div className='addmoney_main_division'>
                                <div className='addmoney_close_button'
                                    onClick={() => {
                                        setAddMoneyFlag(false)
                                    }}
                                ><i class="fa-solid fa-xmark add_money_popup_close_icon"></i></div>

                                <div>
                                    <div className='addmoney_popup_title_name'>ADD MONEY</div>

                                    <div className='addmoney_popup_label_text'>
                                        <div className='addmoney_popup_amount_title'>AMOUNT ($)</div>
                                        <input type="text" class="addmoneyformControl_popup" placeholder=""
                                            onChange={(e) => {
                                                setMoney(e.target.value)
                                            }}
                                        ></input>
                                    </div>

                                    <div className='addmoney_popup_credit_name_dropdown'>
                                        <div className='addmoney_popup_credit_title'
                                        >DEBIT/CREDIT</div>
                                        <select class="addmoneycreditformControl_popup" aria-label=".form-select-sm example"
                                            onChange={(e) => {
                                                setCreditDebit(e.target.value)
                                                console.log(e.target.value)
                                            }}
                                        >
                                            <option selected>CREDIT</option>
                                            <option >DEBIT</option>
                                        </select>
                                    </div>

                                    {CreditDebit === "CREDIT" ? <div className='addmoney_popup_label_text'>
                                        <div className='addmoney_popup_amount_title'>AMOUNT PAID ($)</div>
                                        <input type="number" class="addmoneyformControl_popup" placeholder=""
                                            onChange={(e) => {
                                                setAmountPaid(e.target.value)
                                            }}
                                        ></input>
                                    </div> : <></>}

                                    <div className='addmoney_popup_label_text'>
                                        <div className='addmoney_popup_discription_title'>DESCRIPTION</div>
                                        <textarea type="text" rows="3" class="addmoney_description_popup" placeholder=""
                                            onChange={(e) => {
                                                setDescription(e.target.value)
                                            }}
                                        ></textarea>
                                    </div>

                                    <div className='addmoney_popup_credit_name_dropdown'>
                                        <div className='addmoney_popup_booking_mode_title'>BOOKING MODE</div>
                                        <select class="addmoneycreditformControl_popup" aria-label=".form-select-sm example"
                                            onChange={(e) => {
                                                setPaymentType(e.target.value)
                                            }}
                                        >
                                            <option selected>CASH</option>
                                            <option >UPI</option>
                                            <option >CARD</option>

                                        </select>
                                    </div>

                                    <div className='addmoney_popup_add_amountn_button'>
                                        <button type="button" class="addmoney-popup-btn-add-amount"
                                            onClick={() => {
                                                var url = window.API_PREFIX + "admin/addmoney";

                                                fetch(url, {
                                                    method: 'POST',
                                                    headers: {
                                                        'Content-type': 'application/json',
                                                    },
                                                    body: JSON.stringify({
                                                        "Token": localStorage.getItem("Token"),
                                                        "UserId": UID,
                                                        "Amount": Money,
                                                        "AmountPaid": AmountPaid,
                                                        "CreditDebit": CreditDebit,
                                                        "Desc": Description,
                                                        "PaymentType": PaymentType
                                                    }),

                                                }).then(resp => resp.json()).then(data => {
                                                    console.log(data)
                                                    if (data['status'] == "1") {
                                                        setAddMoneyFlag(false)
                                                        alert("Money added")
                                                        window.location.reload()
                                                    }
                                                    else if (data['status'] == "-1") {
                                                        alert("Not enough balance")
                                                    }

                                                })
                                            }}
                                        >ADD AMOUNT</button>
                                    </div>

                                </div>
                            </div>
                        </Popup>
                        <Popup
                            modal
                            contentStyle={AddMoneyStyle}
                            open={AddCoupons}
                            closeOnDocumentClick={true}
                            onClose={() => { setAddCoupons(false) }}
                        >
                            <div className='addmoney_main_division'>
                                <div className='addmoney_close_button'
                                    onClick={() => {
                                        setAddCoupons(false)
                                    }}
                                ><i class="fa-solid fa-xmark add_money_popup_close_icon"></i></div>

                                <div>
                                    <div className='addmoney_popup_title_name'>ADD Coupons</div>
                                    {/* <div className='addcoupon_popup_label_text'>
                                        <div className='addcoupon_popup_amount_title'>Coupon Name</div>
                                        <input type="text" class="addCouponformControl_popup" placeholder=""></input>
                                    </div> */}
                                    <div className="addcoupon_popup_label_text">
                                    <div className='addcoupon_popup_amount_title'>Coupon Image</div>
                                    <img src ={showImg} height="100px" width='100px'></img>
                                        <div><button type="button" className="profilesettings_upload_photo_button btn">
                                            <i className="fa fa-upload profilesettings_upload_icon"></i> Upload Photo
                                            <input type="file" accept="image/gif, image/jpeg, image/png"
                                                  onChange={(e) => {
                                                    setImg(e.target.files[0]);
        
                                                    setshowImg(URL.createObjectURL(e.target.files[0]));
                                                }}
                                            />
                                        </button></div>
                                    </div>
                                    {/* <div className='addcoupon_popup_label_text'>
                                        <div className='addcoupon_popup_amount_title'>Unique Random Code</div>
                                        <input type="text" class="addCouponformControl_popup" placeholder=""
                                        onChange={(e)=>{
                                        setUniquecode(e.target.value)
                                        
                                        }}  
                                        ></input>
                                    </div> */}
                                    <div className='addcoupon_popup_label_text'>
                                        <div className='addcoupon_popup_amount_title'>Desciption</div>
                                        <input type="text" rows="3" class="addCouponformControl_popup" placeholder=""
                                          onChange={(e)=>{
                                            setDesc(e.target.value)
                                            
                                            }}  
                                        ></input>
                                    </div>
                                    <div className='addcoupon_popup_label_text'>
                                        <div className='addcoupon_popup_amount_title'>Expiry Date</div>
                                        <input type="number" rows="3" class="addCouponformControl_popup" placeholder=""
                                          onChange={(e)=>{
                                            setExpiryDate(e.target.value)
                                            
                                            }}  
                                        ></input>
                                    </div>
                                    <div className='addmoney_popup_add_amountn_button'>
                                        <button type="button" class="addmoney-popup-btn-add-amount"
                                        onClick={()=>{
                                            var Url =
                                            window.API_PREFIX + "booking/addcoupon";

                                        var uploadData = new FormData();
                                        uploadData.append(
                                            "Token",
                                            localStorage.getItem("Token")
                                        );
                                        uploadData.append("Description", Desc);
                                        uploadData.append("Image", Img);
                                        uploadData.append("ExpiryDays", ExpiryDate);
                                        uploadData.append("UID",UID);
                     

                                        fetch(Url, {
                                            method: "POST",
                                            // headers: {
                                            //     'Content-type': 'application/json',
                                            // },
                                            body: uploadData,
                                        })
                                            .then((resp) => resp.json())
                                            .then((data) => {
                                                console.log(data);
                                                if (data["status"] === "1") {
                                                    alert("Coupon Added");
                                                    setAddCoupons(false);
                                                
                                        }})
                                        
            
                                        }}
                                        >ADD COUPON</button>
                                    </div>
                                </div>
                            </div>
                        </Popup>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminUsersProfile;





// const onSelectChange = (newSelectedRowKeys) => {
//     console.log('selectedRowKeys changed: ', selectedRowKeys);
//     setSelectedRowKeys(newSelectedRowKeys);
// };

// const rowSelection = {
//     selectedRowKeys,
//     onChange: onSelectChange,
// };
// const hasSelected = selectedRowKeys.length > 0;