import "./dashboard.css"
import { useEffect, useLayoutEffect, useState } from "react"
import { useLocation } from 'react-router-dom';
import right_tick from '../../media/download (3).png'
import Badge from 'react-bootstrap/Badge';




export default function Userprofile() {

    const { state } = useLocation();

    const [UserData, setUserData] = useState({});
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {

        var url = window.API_PREFIX + "user/dashboard";

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify({ "Token": localStorage.getItem("Token") }),
        }).then(resp => resp.json()).then(data => {

            if (data['status'] == "1") {

                setUserData({ ...data["detail"] })

            }
            else {
       
            }

        })

    }, [])



    return (
        <div class="user-detail-main">
        <div class="gaming-name-divition">
            <p class="gaming-name">Name</p>

            <p class="user-gaming-name">{UserData["UserName"]}</p>
            <p class="user-edit-option-none"></p>
        </div>
        <div class="gaming-name-divition">
            <p class="gaming-name">Gaming Name</p>
            <p class="user-gaming-name">{UserData["Nickname"]}</p>
            <p class="user-edit-option"></p>
        </div>
        <div class="gaming-name-divition">
            <p class="gaming-name">Email</p>
            <p class="user-gaming-name">{UserData["Email"]}</p>
            <p class="user-edit-option"></p>
        </div>
        <div class="gaming-name-divition">
            <p class="gaming-name">Phone Number</p>
            <p class="user-gaming-name">{UserData["MobileNumber"]}</p>
            <p class="user-edit-option"></p>
        </div>
        <div class="gaming-name-divition">
            <p class="gaming-name">date of birth</p>
            <p class="user-gaming-name">{UserData["DOB"]}</p>
            <p class="user-edit-option-none"></p>
        </div>
        <div class="gaming-name-divition">
            <p class="gaming-name">adharcard</p>
            <p class="user-gaming-name">{UserData["Aadharcard_Num"]}</p>
            <div class="user-edit-option-none">
                {UserData["IsAadharVerify"] ?
                    <Badge bg="success">Verified</Badge> :
                    <Badge bg="danger">No Verified</Badge>}
            </div>

            {/* <p class="user-edit-option-none"></p> */}
        </div>

        <div class="gaming-name-divition">
            <p class="gaming-name">Change Profile</p>
            <input
                style={{ "cursor": "pointer" }}
                type="file"
                name="myImage"
                onChange={(event) => {
                  
                    setSelectedImage(event.target.files[0]);
                }}
            />
            <i style={{ "color": "white", "cursor": "pointer" }} class="fa-solid fa-upload"
                onClick={(e) => {
                    var uploadData = new FormData();
                    uploadData.append("Token", localStorage.getItem("Token"));
                    // uploadData.append("FirstName", FirstName);
                    // uploadData.append("LastName", LastName);
                    // uploadData.append("Email", Email);
                    // uploadData.append("PhoneNumber", MobileNumber);
                    uploadData.append("Pic", selectedImage);


                    var url = window.API_PREFIX + "user/edit_Photo";


                    fetch(url, {
                        method: 'POST',
                        body: uploadData
                    }).then(resp => resp.json()).then(data => {

                        if (data['status'] == "1") {
                            window.location.reload()
                            // setresponseData({...data["detail"]})

                        }
                    })
                }}
            ></i>

        </div>

    </div>
    )
}