import "./bookingDark.css";
import SlotSection from "./SlotSection";
import React, { useState, useEffect, useLayoutEffect } from "react";




const BookingPage = () => {

  const [slots, setslots] = useState([])
  const [date, setdate] = useState([])
  const [selectdate, setselectdate] = useState([])
  const [qantityslot, setqantityslot] = useState([0])
  const [sendQnumber, setsendQnumber] = useState(1)
  const [current_date_selected, setcurrent_date_selected] = useState("")
  const [quantity, setquantity] = useState(0);



  const weekday = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const month = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];


  const RowSlotSize = 7

  function breakList(arrayList) {
    var returnList = []

    for (let i = 0; i < arrayList.length; i += RowSlotSize) {
      const chunk = arrayList.slice(i, i + RowSlotSize);
      returnList.push(chunk)
    }

    return returnList
  }


  function arragequantity(AllTime) {
    var tmp = []

    for (let i in AllTime) {
      tmp.push(Number(i) + 1)
    }

    setqantityslot([...tmp])

  }



  const handleChange = (e) => {
    setsendQnumber(e.target.value);
  };


  useEffect(() => {
    var url = window.API_PREFIX + "booking/show_unbooked_slot";



    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((resp) => resp.json())
      .then((data) => {

        if (data["status"] == "1") {


          arragequantity(data["status+slot"][0]["Time"])
          var tmp = data["status+slot"]
          for (var eachSeat = 0; eachSeat < tmp.length; eachSeat++) {

            tmp[eachSeat]["Time"] = breakList(tmp[eachSeat]["Time"])
            tmp[eachSeat]["Slot_Status"] = breakList(tmp[eachSeat]["Slot_Status"])
            tmp[eachSeat]["Slot_id"] = breakList(tmp[eachSeat]["Slot_id"])



          }
          setslots([...tmp])
          setdate(data["dates"])

          var tmp = []

          for (var i = 0; i < data["dates"].length; i++) {
            tmp.push(0);
          }
          tmp[0] = 1;

          setselectdate(tmp)






          setcurrent_date_selected(data["dates"][0])

        }

        else {
          console.warn("api not hitted")

        }


      })
  }, [])



  return (<>
    <body>

      <div className="d-flex">
        <div className="d-flex date-box-division">
          {date.map((currentDate, dateindex) => {

            const d = new Date(currentDate);
            let day = weekday[d.getDay()];
            let monthname = month[d.getMonth()];
            let datenumber = d.getDate();



            return (
              <button type="button"

                className={selectdate[dateindex] == 1 ? "btn date-box1 mx-3" : "btn date-box mx-3"}
                onClick={(e) => {
                  {
                    if (dateindex == 0) {
                      var url = window.API_PREFIX + "booking/show_unbooked_slot";
                      fetch(url, {
                        method: "GET",
                        headers: {
                          "Content-type": "application/json",
                        },
                      })
                        .then((resp) => resp.json())
                        .then((data) => {
                          if (data["status"] == "1") {

                            var tmp = data["status+slot"]
                            for (var eachSeat = 0; eachSeat < tmp.length; eachSeat++) {

                              tmp[eachSeat]["Time"] = breakList(tmp[eachSeat]["Time"])
                              tmp[eachSeat]["Slot_Status"] = breakList(tmp[eachSeat]["Slot_Status"])
                              tmp[eachSeat]["Slot_id"] = breakList(tmp[eachSeat]["Slot_id"])



                            }
                            setslots([...tmp])

                            var tmp = []

                            for (var i = 0; i < selectdate.length; i++) {
                              tmp.push(0);
                            }
                            tmp[dateindex] = 1;

                            setselectdate(tmp)

                            setcurrent_date_selected(date[dateindex])



                            console.warn("API HITTED ")

                          }

                          else {
                            console.warn("api not hitted ")

                          }


                        })

                    }
                    else {
                      var url = window.API_PREFIX + "booking/show_unbooked_slot_advance";



                      fetch(url, {
                        method: "POST",
                        headers: {
                          "Content-type": "application/json",
                        },
                        body: JSON.stringify({ 'DATE': currentDate, 'Token': sessionStorage.getItem('Token') })
                      })
                        .then((resp) => resp.json())
                        .then((data) => {
                          console.log(data)
                          if (data["status"] == "1") {



                            var tmp = data["status+slot"]
                            for (var eachSeat = 0; eachSeat < tmp.length; eachSeat++) {

                              tmp[eachSeat]["Time"] = breakList(tmp[eachSeat]["Time"])
                              tmp[eachSeat]["Slot_Status"] = breakList(tmp[eachSeat]["Slot_Status"])
                              tmp[eachSeat]["Slot_id"] = breakList(tmp[eachSeat]["Slot_id"])



                            }
                            setslots([...tmp])
                            var tmp = []

                            for (var i = 0; i < selectdate.length; i++) {
                              tmp.push(0);
                            }
                            tmp[dateindex] = 1;

                            setselectdate(tmp)

                            setcurrent_date_selected(date[dateindex])


                            console.warn("API HITTED ADVANCE")

                          }

                          else {
                            console.warn("api not hitted ADVANCE")

                          }


                        })
                    }
                  }




                  var tmp = []
                  for (var i = 0; i < selectdate.length; i++) {
                    tmp.push(0);
                  }
                  tmp[dateindex] = 1
                  setselectdate(tmp)
                }}


              >
                {day}-{datenumber}-{monthname}</button>)
          })}

          {/* <div className="time-slote-select">
          <label className="time_slote_lable"
          >Choose slot:</label>
          <select className="User_slote_select_dropdown" onChange={handleChange}>
            {qantityslot.map((c) => {
              return (<><option >{c}</option></>);
            })}
          </select>
        </div> */}


        </div>

        <div className='button_div'>
          <p className="button_div_bookslot_tag">Choose Slot : [Each Slot of 30 Min] </p>
          <button type="button" class="btn minus_button"
            onClick={() => {
              if (sendQnumber <= 0) {
                setquantity(0);
              } else {
              setsendQnumber(sendQnumber-1);
              }
            }}><i class="fa-solid fa-minus minus_sign"></i></button>
          <div className='quantity'>{sendQnumber*30}</div>
          <button type="button" class="btn plus_button"
            onClick={() => {
              setsendQnumber(sendQnumber+1);
            }}><i class="fa-solid fa-plus"></i></button>
        </div>
      </div>

      <SlotSection data={[...slots]} usertype={"U"} Quantityslot={sendQnumber} Date={current_date_selected}
      />
    </body></>);

}



export default BookingPage