
import "./dashboard.css"
import { Link, useNavigate, NavLink } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"


import MyProfileHeader from './MyProfileHeader';


export default function MyProfileLayout()
{
return(<>
<MyProfileHeader/>
</>
)
}