import './MyCoupon.css'
import frenchfries from '../../media/FrenchfriesCopoun.png'
import friesbw from '../../media/friesbw.png'
import redbull from '../../media/RedbullCopoun.png'
import { useEffect, useLayoutEffect, useState } from "react"
// import contactformimage from '../../media/contactformimg.jpg';


export default function MyCoupon() {

    const [Mycup, SetMycup] = useState([true, false, false])

    const [Usedcoupondata, setUsedcoupondata] = useState([])
    const [Activecoupondata, setActivecoupondata] = useState([])
    const [Expiredcoupondata, setExpiredcoupondata] = useState([])
    useEffect(() => {

        var url = window.API_PREFIX + "booking/allcoupons";

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify({ "Token": localStorage.getItem("Token") }),
        }).then(resp => resp.json()).then(data => {
            console.log(data)

            if (data['status'] === "1") {
                setUsedcoupondata([...data['Usedcoupons']]);
                setActivecoupondata([...data['Activecoupons']]);
                setExpiredcoupondata([...data['Expiresdcoupon']]);
            }
            else {
                alert("Error");
            }
        })
    }, [])
    return (
        <div>
            <div className='mycoupon_main_diviison'>
                <div className='mycoupon_second_diviison'>
                    <div className={Mycup[0] ? 'coupon_title_active' : 'coupon_title'}
                        onClick={() => SetMycup([true, false, false])}>
                        Available coupon
                    </div>
                    <div className={Mycup[1] ? 'coupon_title_active' : 'coupon_title'} onClick={() => SetMycup([false, true, false])}>
                        Used coupon
                    </div>
                    <div className={Mycup[2] ? 'coupon_title_active' : 'coupon_title'} onClick={() => SetMycup([false, false, true])}>
                       Expired coupon
                    </div>
                </div>

                {Mycup[0] ? <div className='mycoupon_diviison'>
                    {
                        Activecoupondata.map((eachCoupon, CouponIndex) => {
                            return (
                                // <div className='mycoupon_frenchfries_image_division'>
                                // <img className='mycoupon_frenchfries_image' src={eachCoupon.Image} alt='img'></img>
                                // <div>{eachCoupon.UniqueCode}</div>
                                // <div>{eachCoupon.Description}</div>
                                // <p>{eachCoupon}</p>
                                //   </div>
                                <div className='containor'>
                                    <div className='coupon_card'>
                                        <div className='circle1'></div><div className='circle2'></div>
                                        <img className='coupon_card_image' src={eachCoupon.Image}></img>
                                        <div className='circle3'></div>
                                        <div className='coupon_card_dotted_border'></div>
                                        <div className='coupon_card_number_division'><p className='coupon_card_number'>|| {eachCoupon.UniqueCode} ||</p>
                                            <p className='coupon_discription'>Terms And Condition Apply*</p></div>
                                        <div className='circle4'></div>
                                        <div className='circle5'></div>
                                        <div className='circle6'></div>
                                    </div>
                                </div>
                            )
                        })
                    }</div> : null}

                {Mycup[1] ? 
            
                <div className='mycoupon_diviison'>


                    {Usedcoupondata.map((eachCoupon, CouponIndex) => {
                            return (
                    <div className='containor_disable'>
                    <div className='coupon_card_disable'>
                        <div className='circle1_disable'></div><div className='circle2_disable'></div>
                        <img className='coupon_card_image_disable' src={eachCoupon.Image}></img>
                        <div className='circle3_disable'></div>
                        <div className='coupon_card_dotted_border_disable'></div>
                        <div className='coupon_card_number_division_disable'>
                            <p className='coupon_card_number_disable'>||{eachCoupon.UniqueCode} ||</p>
                            <p className='coupon_discription'>Terms And Condition Apply*</p>
                        </div>

                        <div className='circle4_disable'></div>
                        <div className='circle5_disable'></div>
                        <div className='circle6_disable'></div>
                    </div>
                </div>)
                        })
                    }

                </div> : null}

                {Mycup[2] ? <div className='mycoupon_diviison'>


                        {Expiredcoupondata.map((eachCoupon, CouponIndex) => {
                                return (
                        <div className='containor_disable'>
                        <div className='coupon_card_disable'>
                            <div className='circle1_disable'></div><div className='circle2_disable'></div>
                            <img className='coupon_card_image_disable' src={eachCoupon.Image}></img>
                            <div className='circle3_disable'></div>
                            <div className='coupon_card_dotted_border_disable'></div>
                            <div className='coupon_card_number_division_disable'>
                                <p className='coupon_card_number_disable'>||{eachCoupon.UniqueCode} ||</p>
                                <p className='coupon_discription'>Terms And Condition Apply*</p>
                            </div>

                            <div className='circle4_disable'></div>
                            <div className='circle5_disable'></div>
                            <div className='circle6_disable'></div>
                        </div>
                        </div>)
                            })
                        }

                        </div> : null}
            </div>
           

            {/* <div className='mycoupon_frenchfries_image_division'>
            <img className='mycoupon_frenchfries_image' src={redbull} alt='img'></img>
        </div>
        <div className='mycoupon_frenchfries_image_division'>
            <img className='mycoupon_frenchfries_image' src={frenchfries} alt='img'></img>
        </div>
        <div className='mycoupon_frenchfries_image_division'>
            <img className='mycoupon_frenchfries_image' src={frenchfries} alt='img'></img>
        </div> */}


            {/* <div className='containor_disable'>
                    <div className='coupon_card_disable'>
                        <div className='circle1_disable'></div><div className='circle2_disable'></div>
                        <img className='coupon_card_image_disable' src={friesbw}></img>
                        <div className='circle3_disable'></div>
                        <div className='coupon_card_dotted_border_disable'></div>
                        <div className='coupon_card_number_division_disable'>
                            <p className='coupon_card_number_disable'>|| K98676GHFDF ||</p>
                            <p className='coupon_discription'>Terms And Condition Apply*</p>
                        </div>

                        <div className='circle4_disable'></div>
                        <div className='circle5_disable'></div>
                        <div className='circle6_disable'></div>
                    </div>
                </div> */}


            {/* </div> */}
        </div>
    )
}