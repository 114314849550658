import "./bookingDark.css";
import SlotSection from "./SlotSection";
import React, { useState, useEffect, useLayoutEffect } from "react";




const AdminBookingPage = () => {

  const [slots, setslots] = useState([])
  const [date, setdate] = useState([])
  const [selectdate, setselectdate] = useState([])
  const [qantityslot, setqantityslot] = useState([0])
  const [sendQnumber, setsendQnumber] = useState(1)
  const [current_date_selected, setcurrent_date_selected] = useState("")
  const [allUser,setallUser] = useState([])
  const [UserId,setUserId] = useState("")



  const weekday = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const month = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];


  const RowSlotSize = 7

  function breakList(arrayList) {
    var returnList = []

    for (let i = 0; i < arrayList.length; i += RowSlotSize) {
      const chunk = arrayList.slice(i, i + RowSlotSize);
      returnList.push(chunk)
    }

    return returnList
  }


  function arragequantity(AllTime) {
    var tmp = []

    for (let i in AllTime) {
      tmp.push(Number(i) + 1)
    }

    setqantityslot([...tmp])

  }



  const handleChange = (e) => {
    setsendQnumber(e.target.value);



  };


  useEffect(() => {
    var url = window.API_PREFIX + "admin/all_user_detail";


 

    fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body:JSON.stringify({
        "Token":localStorage.getItem("Token")
      })
    })
      .then((resp) => resp.json())
      .then((data) => {

        if (data["status"] == "1") {

          setallUser([...data['UserData']])
          setUserId(data['UserData'][0]['id'])
        }})





    var url = window.API_PREFIX + "booking/show_unbooked_slot";



    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((resp) => resp.json())
      .then((data) => {

        if (data["status"] == "1") {


          arragequantity(data["status+slot"][0]["Time"])
          var tmp = data["status+slot"]
          for (var eachSeat = 0; eachSeat < tmp.length; eachSeat++) {

            tmp[eachSeat]["Time"] = breakList(tmp[eachSeat]["Time"])
            tmp[eachSeat]["Slot_Status"] = breakList(tmp[eachSeat]["Slot_Status"])
            tmp[eachSeat]["Slot_id"] = breakList(tmp[eachSeat]["Slot_id"])
            tmp[eachSeat]["BookedSlot"] = breakList(tmp[eachSeat]["BookedSlot"])



          }
          setslots([...tmp])
          setdate(data["dates"])

          var tmp = []

          for (var i = 0; i < data["dates"].length; i++) {
            tmp.push(0);
          }
          tmp[0] = 1;

          setselectdate(tmp)






          setcurrent_date_selected(data["dates"][0])

        }

        else {
          console.warn("api not hitted")

        }


      })
  }, [])



  return (<>
    <body>

      <div className="d-flex admin-date-box-division">
        {date.map((currentDate, dateindex) => {

          const d = new Date(currentDate);
          let day = weekday[d.getDay()];
          let monthname = month[d.getMonth()];
          let datenumber = d.getDate();



          return (
            <button type="button"

              className={selectdate[dateindex] == 1 ? "btn date-box1 mx-3" : "btn date-box mx-3"}
              onClick={(e) => {
                {
                  if (dateindex == 0) {
                    var url = window.API_PREFIX + "booking/show_unbooked_slot";
                    fetch(url, {
                      method: "GET",
                      headers: {
                        "Content-type": "application/json",
                      },
                    })
                      .then((resp) => resp.json())
                      .then((data) => {
                        if (data["status"] == "1") {

                          var tmp = data["status+slot"]
                          for (var eachSeat = 0; eachSeat < tmp.length; eachSeat++) {

                            tmp[eachSeat]["Time"] = breakList(tmp[eachSeat]["Time"])
                            tmp[eachSeat]["Slot_Status"] = breakList(tmp[eachSeat]["Slot_Status"])
                            tmp[eachSeat]["Slot_id"] = breakList(tmp[eachSeat]["Slot_id"])
            tmp[eachSeat]["BookedSlot"] = breakList(tmp[eachSeat]["BookedSlot"])




                          }
                          setslots([...tmp])

                          var tmp = []

                          for (var i = 0; i < selectdate.length; i++) {
                            tmp.push(0);
                          }
                          tmp[dateindex] = 1;

                          setselectdate(tmp)

                          setcurrent_date_selected(date[dateindex])



                          console.warn("API HITTED ")

                        }

                        else {
                          console.warn("api not hitted ")

                        }


                      })

                  }
                  else {
                    var url = window.API_PREFIX + "booking/show_unbooked_slot_advance";



                    fetch(url, {
                      method: "POST",
                      headers: {
                        "Content-type": "application/json",
                      },
                      body: JSON.stringify({ 'DATE': currentDate, 'Token': sessionStorage.getItem('Token') })
                    })
                      .then((resp) => resp.json())
                      .then((data) => {
                        console.log(data)
                        if (data["status"] == "1") {



                          var tmp = data["status+slot"]
                          for (var eachSeat = 0; eachSeat < tmp.length; eachSeat++) {

                            tmp[eachSeat]["Time"] = breakList(tmp[eachSeat]["Time"])
                            tmp[eachSeat]["Slot_Status"] = breakList(tmp[eachSeat]["Slot_Status"])
                            tmp[eachSeat]["Slot_id"] = breakList(tmp[eachSeat]["Slot_id"])
            tmp[eachSeat]["BookedSlot"] = breakList(tmp[eachSeat]["BookedSlot"])




                          }
                          setslots([...tmp])
                          var tmp = []

                          for (var i = 0; i < selectdate.length; i++) {
                            tmp.push(0);
                          }
                          tmp[dateindex] = 1;

                          setselectdate(tmp)

                          setcurrent_date_selected(date[dateindex])


                          console.warn("API HITTED ADVANCE")

                        }

                        else {
                          console.warn("api not hitted ADVANCE")

                        }


                      })
                  }
                }




                var tmp = []
                for (var i = 0; i < selectdate.length; i++) {
                  tmp.push(0);
                }
                tmp[dateindex] = 1
                setselectdate(tmp)
              }}


            >
              {day}-{datenumber}-{monthname}</button>)
        })}
        <div className="time-slote-select">
          <label className="time_slote_lable"
          >Choose slot:</label>
          <select className="User_slote_select_dropdown" onChange={handleChange}>
            {qantityslot.map((c) => {
              return (<><option >{c}</option></>);
            })}
          </select>
        </div>

        <div className="time-slote-select mx-3">
          <label className="time_slote_lable"
          >Choose User:</label>
          <select className="User_slote_select_dropdown" 
          onChange={(e)=>{
              setUserId(e.target.value.split("--")[0])
              console.log(e.target.value.split("--")[0])
          }}
          >
            {allUser.map((Users) => {
              return (<option value={Users['id']}
              onClick={()=>{
                setUserId(Users['id'])
              
              }} >{Users['id']}--{Users["USERNAME"]}--{Users["NAME"]}</option>);
            })}
          </select>
        </div>
        

      </div>

      <SlotSection data={[...slots]} usertype={"U"} Quantityslot={sendQnumber} Date={current_date_selected} UserId={UserId}
      />
    </body></>);

}



export default AdminBookingPage
