import "./Addcarddetail.css"
import Addwalletcard from '../../media/K9 Gaming Loyalty Card Front.png'
import { useEffect, useState } from "react"
function Addcarddetail() {

    const [UserCard, setUserCard] = useState([])


    useEffect(() => {
        var Url = window.API_PREFIX + 'user/mycards'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                {
                    "Token": localStorage.getItem("Token")
                }
            ),
        }).then(resp => resp.json()).then(data => {
            if (data['status'] == "1") { setUserCard([...data["UserCards"]]) }
        })
    }, [])
    return (
        <div>
            <div className="user_add_card_detail">
                <h4 className="user_add_card_heading_detail">Card</h4>
                {UserCard.map((eachCard, index) => {
                    return (<div className="user_add_card_division_detail">

                        <div className="user_add_card_number_division_detail">
                            <img className="add_card_detail_image" src={Addwalletcard}></img>
                            <h5 className="user_add_card_heading_detail_number"> {eachCard}</h5>
                        </div>
                    </div>

                    )
                })}
                {UserCard.length === 0 ? <h4 className="user_add_card_heading_detail">No card register yet</h4> : null}

            </div>

        </div>
    )
}
export default Addcarddetail