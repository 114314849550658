import K9logo from "../../media/K9 Gaming Logo Website.png"
import { useState,useEffect } from 'react';
import { useLocation } from "react-router-dom";
import './Useraddmoney.css';
import userEvent from "@testing-library/user-event";
export default function Useraddmoney() {
    const [UserData,setUserData]= useState({});

    const [TotalPrice,setTotalPrice] = useState();
    const [order_id,setorder_id] = useState();
const __DEV__ = document.domain === 'localhost'

useEffect(() => {

    var url = window.API_PREFIX + "user/dashboard";

    fetch(url, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
        },
        body: JSON.stringify({ "Token": localStorage.getItem("Token") }),
    }).then(resp => resp.json()).then(data => {
       
        if (data['status'] == "1") {
            
setUserData({...data["detail"]})

        }
        else {
          {}
        }
        
    })

  },[])







    return (
        <>
            <div className='add-money-main-division'>
                {/* <div className='add-money-division'>
                    <p className='add-money'>Add money</p>
                </div> */}
                <div>Add Money:{UserData["Wallet"]}</div>
                <div className='add-offer-and-pay'>
                    <input type="number" id="fname" name="fname" placeholder='Enter Money' 
                    onChange={(e)=>{
                        setTotalPrice(e.target.value)
                    }}
                    />
                    <div className='promocode-apply'>
                        <div className='promocode'>
                            <input type="text" className='promocode-text' id="fname" name="fname" placeholder='Promocode' />
                        </div>
                        <div className='apply'>
                            Apply
                        </div>
                    </div>
                    <div>
                    <div className='procced-to-pay'
                    
                     onClick={(e)=>{
   
                      
                  if(TotalPrice>0){
                        var Url1 = window.API_PREFIX + 'booking/addmoney_wallet'
                        fetch(Url1, {
                            method: 'POST',
                            headers: {
                                'Content-type': 'application/json',
                            },
                            body: JSON.stringify({'Token': localStorage.getItem('Token'), "Price":TotalPrice}),
                        }).then(resp => resp.json()).then(data => {
                            if (data['status'] == "1")
                            {
                              setorder_id(...data['order_id'])
                              const options = {
                                // key: __DEV__ ? 'rzp_test_QoYTwM9rHNMFDE' : 'rzp_live_TRoo0KyQM1qbTN',
                                key:'rzp_test_QoYTwM9rHNMFDE',
                                currency: 'INR',
                                amount: TotalPrice*100,
                                order_id: data['order_id'],
                                name: data['Name'],
                                description: 'Thank you for nothing. Please give us some money',
                                image: K9logo,
                                handler: function (response) {

                                    var Url1 = window.API_PREFIX + 'booking/verify_walletmoney'
                                    fetch(Url1, {
                                        method: 'POST',
                                        headers: {
                                            'Content-type': 'application/json',
                                        },
                                        body: JSON.stringify({
                                        'Token': localStorage.getItem('Token'),
                                        "razorpay_order_id":response.razorpay_order_id,
                                        "razorpay_payment_id":response.razorpay_payment_id,
                                        "razorpay_signature":response.razorpay_signature,

                                        "Price":TotalPrice}),
                                    }).then(resp => resp.json()).then(data => {
                                      
                                        if (data['status'] == "1")
                                        {
                                            window.location.reload()
                                        }})


                                //   alert(response.razorpay_payment_id)
                                //   alert(response.razorpay_order_id)
                                //   alert(response.razorpay_signature)
                                },
                                prefill: {
                                  name:"Utsav",
                                //   email: 'sdfdsjfh2@ndsfdf.com',
                                  phone_number: '9899999999'
                                }
                              }
                              const paymentObject = new window.Razorpay(options)
                              paymentObject.open()
                      
                      
                      
                            }
                      
                      
                            else
                            {
                      console.warn("something went wrong")
                            }
                    
                        
                          })
                        }
                      }}
                    
                    
                    
                    
                    
                    >Proceed To Pay</div>
                    </div>
                </div>
            </div>
        </>
    )}