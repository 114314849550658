import K9gamingLogo from '../media/K9 Gaming Logo Website.png'
import ProfileImg from '../media/ProfilePic.jpeg'
import { Link, useNavigate, NavLink } from 'react-router-dom';
import { useState, useLayoutEffect, useEffect } from 'react'
import "../Animation/TickIcon.css"
import "./header.css"
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import CircularProgress from '@mui/material/CircularProgress';
import { height } from '@mui/system';
import { toUnitless } from '@mui/material/styles/cssUtils';
import {navigate} from 'react-router-dom'

function Header() {


    const [showSign, setshowSign] = useState(true)
    const [Name, setName] = useState("")
    const [NickName, setNickName] = useState("")
    const [Adharcard, setAdharcard] = useState("")
    const [Email, setEmail] = useState("")
    const [Password, setPassword] = useState("")
    const [ConfPassword, setConfPassword] = useState("")
    const [Checked, setChecked] = useState(false)
    const [CheckedOTP, setCheckedOTP] = useState("0")
    const [Apiotp, setApiotp] = useState("")
    const [Userotp, setUserotp] = useState(-1)
    const [SignupRedirect, setSignupRedirect] = useState(false)
    const [AllFieldFill, setAllFieldFill] = useState(false)
    const [LoginFieldFill, setLoginFieldFill] = useState(false)
    const [showProfileDropdown,setshowProfileDropdown] = useState(false)

    //Login Alert
    const [LoginShowAlert, setLoginShowAlert] = useState(false)
    const [LoginAlertData, setLoginAlertData] = useState({ 'Type': 'success', 'Message': 'done' })
    const [LoginOnspinner, setLoginOnspinner] = useState(false)

    //SignUp Alert   
    const [ShowAlert, setShowAlert] = useState(false)
    const [AlertData, setAlertData] = useState({ 'Type': 'success', 'Message': 'done' })
    const [Onspinner, setOnspinner] = useState(false)
    const [Show, setShow] = useState("password")



    let navigate = useNavigate();


///////////////////////////////////Use Effects and UseLayoutEffect ////////////////////////////////
    useEffect(() => {

        if (localStorage.getItem('Token') === null) {
            setshowSign(true)

        }
        else { setshowSign(false) }
        console.log(!showSign)

        document.addEventListener('mouseup', function (e) {
            if(!showSign){
            var LoginBox = document.getElementById('LoginBox');
            if (!LoginBox.contains(e.target)) {
                LoginBox.style.display = 'none';
            }}

        });

        document.addEventListener('mouseup', function (e) {
            if(!showSign){
            var SignUpBox = document.getElementById('SignUpBox');
            if (!SignUpBox.contains(e.target)) {
                SignUpBox.style.display = 'none';
            }}
        });
    


    })

    useEffect(()=>{
        if (localStorage.getItem('Token') === null) {
            setshowSign(true)

        }
        else { setshowSign(false) }
        console.log(!showSign)
    },[showSign])
    useLayoutEffect(() => {
      

    })

    
///////////////////////////////////Use Effects and UseLayoutEffect ////////////////////////////////

function showLoginPopup()
{  var container = document.getElementById('LoginBox');
container.style.display = 'block';}


///////////////////////////////Refresh Alert and Spinner //////////////////////////////////
    function refreshState() {
        setShowAlert(false)
        setOnspinner(false)
        setCheckedOTP(false)
    }
    function refreshLoginState() {
        setLoginShowAlert(false)
        setLoginOnspinner(false)

    }


///////////////////////////////Refresh Alert and Spinner //////////////////////////////////


    function CheckSignUpFill() {
        if (NickName != "" && Name != "" && Email != "" && Password != "" && ConfPassword != "") { setAllFieldFill(true); }
        else {
            setAllFieldFill(false);
        }


    }

    function CheckLoginFill() {
        if (Email != "" && Password != "") 
        {setLoginFieldFill(true);}
        else {
            setLoginFieldFill(false);
        }


    }


///////////////////////////////////////////API LOGIN and SIGN UP ////////////////////////////////


function send_verification_code(email_to_send_otp) {
    setOnspinner(true)
    var Url = window.API_PREFIX + 'user/issue_otp'
    fetch(Url, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
        },
        body: JSON.stringify({ "Email": email_to_send_otp }),
    }).then(resp => resp.json()).then(data => {
        console.log(data)
        if (data['status'] == "1") {
            setAlertData({ 'Type': 'success', 'Message': 'Verification code sent successfully' })
            setShowAlert(true)
            setApiotp(data['OTP'])
            setOnspinner(false)

        }
        else {
            setAlertData({ 'Type': 'error', 'Message': 'Email Id Already exist' })
            setShowAlert(true)
            setOnspinner(false)
        }
    })
}

    function Register_Single_User() {
    

        if (Userotp == Apiotp || CheckedOTP) {
            if (NickName != "" && Name != "" && Email != "" && Password != "") {
                if (Password == ConfPassword) {
                    var url = window.API_PREFIX + 'user/register'
                    fetch(url, {
                        method: 'POST',
                        headers: {
                            'Content-type': 'application/json',
                        },
                        body: JSON.stringify({
                            "Name": Name,
                            "NickName": NickName,
                            "Email": Email,
                            "Password": Password,
                            "Aadharcard_Num":Adharcard,
                            "DOB":"2000-12-12",
                            "MobileNumber":"2345567890"
                        }),
                    }).then(resp => resp.json()).then(data => {
                        if (data['status'] == "1") {
                            localStorage.setItem("Token", data['Token'])
                            localStorage.setItem("Type", "User")
                            
                            setOnspinner(false)
                        }
                        else {
                            setAlertData({ 'Type': 'error', 'Message': "Email id already taken" })
                            setShowAlert(true)
                            setOnspinner(false)
                            setCheckedOTP(0)
                        }
                    })
                }
                else {
                    setAlertData({ 'Type': 'error', 'Message': "password doesn't match" })
                    setShowAlert(true)
                    setOnspinner(false)
                    setCheckedOTP(0)
                }
            }
            else {
                setAlertData({ 'Type': 'error', 'Message': 'All fields are mandatory to fill' })
                setShowAlert(true)
                setOnspinner(false)
                setCheckedOTP(0)
            }
        }
        else {
            setAlertData({ 'Type': 'error', 'Message': 'Invalid verification code' })
            setShowAlert(true)
            setOnspinner(false)
            setCheckedOTP(0)
        }
    }


    function  Login_single_user(){

        var url = window.API_PREFIX + 'user/login'
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                "Email": Email,
                "Password": Password,
            }),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            if (data['status'] == "1") {
                localStorage.setItem("Token", data['Token'])
                localStorage.setItem("Type", "User")
                setshowSign(false)
                setLoginOnspinner(false)
            }
            else
            { 
                setLoginAlertData({ 'Type': 'error', 'Message': data['message'] })
                setLoginShowAlert(true)
                setshowSign(true)

            }
      })}



      ///////////////////////////////////////////API LOGIN and SIGN UP ////////////////////////////////


      ///////////////////////////////////////////SLIDER////////////////////////////////

    function login_squad(e) {
        var a = document.getElementById("login_individual");
        var b = document.getElementById("login_squad");
        var c = document.getElementById("login-btn");
        a.style.left = "-400px";
        b.style.left = "80px";
        c.style.left = "110px";

    }


    function login_individual(e) {
        var a = document.getElementById("login_individual");
        var b = document.getElementById("login_squad");
        var c = document.getElementById("login-btn");
        a.style.left = "80px";
        b.style.left = "550px";
        c.style.left = "0";

    }



    function signup_squad(e) {
        var x = document.getElementById("signup_individual");
        var y = document.getElementById("signup_squad");
        var z = document.getElementById("signup_btn");

        x.style.left = "-400px";
        y.style.left = "80px";
        z.style.left = "110px";

    }
    function signup_individual(e) {
        var x = document.getElementById("signup_individual");
        var y = document.getElementById("signup_squad");
        var z = document.getElementById("signup_btn");

        x.style.left = "80px";
        y.style.left = "540px";
        z.style.left = "0";

    }


      ///////////////////////////////////////////SLIDER////////////////////////////////

    return (<nav className="navbar">
        <div className="d-flex w-100 p-0">


            <div className=" logo-division d-flex justify-content-start">
                <img className="logo" src={K9gamingLogo} alt="" />
            </div>

            <div className="menu d-flex justify-content-center align-items-center">

                {/* <div 
                // className="header-division-active"
                className={({ isActive }) => (isActive ? "header-division-active" : "header-division")}
                    onClick={(e) => {
                        navigate("/");
                    }}>
                    <div className="header-names" >
                        <a className="glow-on-hover">Home</a>
                    </div>
                </div> */}


                <NavLink
                style={{textDecoration: 'none'}}
                    to="/"
                    className={({ isActive }) => (isActive ? "header-division-active" : "header-division")}>
                    <div className="header-names" >
                        <a className="glow-on-hover">Home</a>
                    </div>

                </NavLink>
                <NavLink
                style={{textDecoration: 'none'}}
                    to={showSign?"/":"/booking"}
                    className={!showSign?({ isActive }) => (isActive ? "header-division-active" : "header-division"):"header-division"}>
                    <div className="header-names" onClick={(e)=>{if(showSign){showLoginPopup()}}} >
                        <a className="glow-on-hover">BOOkING</a>
                    </div>

                </NavLink>
              
                <div className="header-division">
                    <div className="header-names">
                        <a href="#" className="glow-on-hover">SPECIAL OFFERS</a>
                    </div>
                </div>
                <div 
                className="header-division"
                onClick={(e)=>{localStorage.removeItem("Token")}}
                >
                
                    <div className="header-names">
                        <a href="#" className="glow-on-hover">CONTACT US</a>
                    </div>
                </div>
            </div>

            {/*                                        */}
            {showSign?
                <div className="login-division d-flex justify-content-center px-0 align-items-center">
                    <div className="pop-login-button">
                        <a href="#" type="button" className="login-button me-3"
                            onClick={(e) => {
                              showLoginPopup()
                            }}>
                            LogIn
                        </a>
                        <div id="LoginBox" className="login-form">
                            <div className="login-form-division">
                                <div className="login-right-side-division ">
                                    <div className="login-button-box">
                                        <div id="login-btn"></div>
                                        <button type="button" className="login-toggle-btn" onClick={login_individual}>Individual</button>
                                        <button type="button" className="login-toggle-btn" onClick={login_squad}>Squad</button>
                                    </div>

                                    {/* <!-- <div className="form-division"> --> */}

                                    <form id="login_individual" className="input-group" style={{ justifyContent: "center" }}>
                                    {LoginShowAlert ? <Stack sx={{ width: "100%" }} spacing={1}>
                                            <Alert severity={LoginAlertData['Type']}>
                                                <label>{LoginAlertData['Message']}</label>
                                            </Alert>
                                        </Stack> : null}
                                        {LoginOnspinner ? <>
                                            <div style={{ width: '50px', marginLeft: 'auto', marginRight: 'auto' }}>
                                                <Stack sx={{ color: 'grey.100' }} spacing={1} direction="row">
                                                    <CircularProgress color="secondary" />
                                                </Stack>
                                            </div>
                                        </> : null
                                        }
                                        <h2 className="text-white login-text m-0 pb-3">Individual</h2>
                                        <div className="mb-3 d-flex">
                                            <div className="login-icon px-3 py-1 me-2"><i className="fa-solid fa-user text-white"></i></div>
                                            <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Email'

                                                onChange={(e) => {
                                                    setEmail(e.target.value)
                                                    CheckLoginFill()
                                                    refreshLoginState()
                                                }} />
                                        </div>
                                        <div className="mb-3 d-flex">
                                            <div className="login-icon px-3 py-1 me-2"><i className="fa-solid fa-lock text-white"></i></div>
                                            <input type="password" className="form-control" id="exampleInputPassword1" autoComplete="off" placeholder='Password'
                                                onChange={(e) => {
                                                    setPassword(e.target.value)
                                                    CheckLoginFill()
                                                    refreshLoginState()
                                                }} 
                                                onKeyDown={(e) => {
                                                    if (e.key == "Enter") { 
                                                        if(LoginFieldFill)
                                                        {
                                                         Login_single_user()
                                                        }
                                                        e.preventDefault();
                                                    }}}/>
                                        </div>
                                        <div className="mb-3 d-flex w-100">
                                            {/* <div className="w-50"><input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                            <label className="form-check-label text-white" for="exampleCheck1">Remember me</label>
                                        </div> */}
                                            <div className="w-50 d-flex justify-content-end"><a className="login-forget-pss" href="#">Forget Password?</a>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <button type="button" className={LoginFieldFill?"login-succ-individual me-3":"login-succ-individual-disable me-3"}
                                              onKeyDown={(e) => {
                                                if (e.keyCode == 9) {  //tab pressed
                                                    e.preventDefault(); // stops its action
                                                }}}
                                            
                                            onClick={(e) => { 
                                               if(LoginFieldFill)
                                               {
                                                setOnspinner(true)
                                                Login_single_user();
                                               }
                                               e.preventDefault();
                                        }}
                                            >
                                                LogIn
                                            </button>
                                        </div>
                                    </form>




                                    <form id="login_squad" className="input-group" style={{ justifyContent: "center" }}>

                                        <h2 className="text-white login-text m-0 pb-3">Squad</h2>
                                        <div className="mb-3 d-flex">
                                            <div className="icon px-3 py-1 me-2"><i className="fa-solid fa-users"></i></div>
                                            <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                        </div>
                                        <div className="mb-3 d-flex">
                                            <div className="icon px-3 py-1 me-2"><i className="fa-solid fa-lock text-white"></i></div>
                                            <input type="password" className="form-control" id="exampleInputPassword1" />
                                        </div>
                                        <div className="mb-3 d-flex w-100">
                                            {/* <div className="w-50"><input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                            <label className="form-check-label text-white" for="exampleCheck1">Remember me</label>
                                        </div> */}
                                            <div className="w-50 d-flex justify-content-end"><a className="login-forget-pss" >Forget Password?</a>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <button type="button" className="login-succ-squad me-3">
                                                LogIn
                                            </button>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="pop-sign-button">
                        <button type="button" className="signup-button"
                            onClick={(e) => {
                                var SignUpBox = document.getElementById('SignUpBox');
                                SignUpBox.style.display = 'block';
                            }}>
                            SignUp
                        </button>
                        <div id="SignUpBox" className="signup-form">
                            <div className="signup-form-division">
                                <div className="signup-right-side-division ">
                                    <div className="button-box">
                                        <div id="signup_btn"></div>
                                        <button type="button" className="toggle-btn" onClick={signup_individual}>Individual</button>
                                        <button type="button" className="toggle-btn" onClick={signup_squad}>Squad</button>
                                    </div>

                                    <form id="signup_individual" className="input-group">

                                        {ShowAlert ? <Stack sx={{ width: "100%" }} spacing={1}>
                                            <Alert severity={AlertData['Type']}>
                                                <label>{AlertData['Message']}</label>
                                            </Alert>
                                        </Stack> : null}
                                        {Onspinner ? <>
                                            <div style={{ width: '50px', marginLeft: 'auto', marginRight: 'auto' }}>
                                                <Stack sx={{ color: 'grey.100' }} spacing={1} direction="row">
                                                    <CircularProgress color="secondary" />
                                                </Stack>
                                            </div>
                                        </> : null
                                        }
                                        <h2 className="text-white signup-text m-0 pb-3">Individual</h2>

                                        <div className="mb-3 d-flex">
                                            <div className="signup-icon px-3 py-1 me-2"><i className="fa-solid fa-user"></i></div>
                                            <input type="name" className="form-control" id="exampleInputName" placeholder="Name" autoComplete="off"
                                                onChange={(e) => {
                                                    setName(e.target.value)
                                                    CheckSignUpFill()
                                                    refreshState()
                                                }}
                                            />
                                        </div>

                                        <div className="mb-3 d-flex">
                                            <div className="signup-icon px-3 py-1 me-2"><i className="fa-solid fa-user"></i></div>
                                            <input type="nickname" className="form-control" id="exampleInputNickName" placeholder="Nick Name" autoComplete="off"

                                                onChange={(e) => {
                                                    setNickName(e.target.value)
                                                    CheckSignUpFill()
                                                    refreshState()
                                                }} />
                                        </div>

                                        <div className="mb-3 d-flex">
                                            <div className="signup-icon px-3 py-1 me-2"><i className="fa-solid fa-user"></i></div>
                                            <input type="adharcard" className="form-control" id="exampleInputAdhar" placeholder="Adhar card" autoComplete="off"

                                                onChange={(e) => {
                                                    setAdharcard(e.target.value)
                                                    CheckSignUpFill()
                                                    refreshState()
                                                }} />
                                        </div>

                                        <div className="mb-2 d-flex">
                                            <div className="signup-icon px-3 py-1 me-2"><i className="fa-solid fa-envelope"></i></div>
                                            <input type="email" className="form-control" id="exampleInputEmail1" placeholder="Email"
                                                onChange={(e) => {
                                                    setNickName(e.target.value)
                                                    CheckSignUpFill()
                                                    setEmail(e.target.value)
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        CheckSignUpFill()
                                                        send_verification_code(e.target.value)

                                                    }
                                                    setApiotp("-1")
                                                    setUserotp("")

                                                }}
                                            />
                                        </div>

                                        {
                                            ShowAlert ? (<>
                                                <div className="mb-3 d-flex w-100 justify-content-end">

                                                    <div className='mx-4 w-100 EnterOTP'>
                                                        <input type="number" className="form-control w-75" id="exampleInputEmail1" placeholder="Enter OTP"
                                                            onChange={(e) => {
                                                                setUserotp(e.target.value)
                                                                CheckSignUpFill()

                                                            }}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    console.log(Userotp,"UserOTP")
                                                                    console.log(Apiotp,"ApiOTP")
                                                                    if (e.target.value == Apiotp) {
                                                                        setCheckedOTP(1)
                                                                    }
                                                                    else { setCheckedOTP(-1) 
                                                                    }

                                                                }
                                                            }}
                                                        />


                                                    </div>
                                                    {CheckedOTP == 1 ? <div class="wrapper"> <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /> <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg></div> :
                                                        CheckedOTP == -1 ?
                                                            <div class="swal2-icon swal2-error swal2-animate-error-icon" style={{ display: "flex", width: "100px", height: "50px" }}><span class="swal2-x-mark"><span class="swal2-x-mark-line-left"></span><span class="swal2-x-mark-line-right"></span></span></div> : null
                                                    }

                                                    <div className="w-50 d-flex justify-content-end"><a className="send-otp" onClick={(e) => { send_verification_code(Email) }}>Send OTP</a></div>

                                                </div>
                                            </>

                                            ) : (
                                                <></>
                                            )}



                                        {/* <div className="mb-3 d-flex">
                                        <div className="signup-icon px-3 py-1 me-2"><i className="fa-solid fa-user"></i></div>
                                        <input type="nickname" className="form-control" id="exampleInputEmail1" placeholder="Enter OTP" />
                                    </div> */}

                                        {/* <div className="mb-2 d-flex">
                                        <div className="signup-icon px-3 py-1 me-2"><i className="fa-solid fa-phone"></i></div>
                                        <input type="contact" className="form-control" id="exampleInputEmail1" placeholder="Contact Number" />
                                    </div> */}

                                        {/* <div className="mb-3 d-flex w-100 justify-content-end">
                                        <div className="w-50 d-flex justify-content-end"><a className="send-otp" href="">Send OTP</a></div>
                                    </div> */}

                                        <div className="mb-3 d-flex">
                                            <div className="signup-icon px-3 py-1 me-2"><i className="fa-solid fa-lock"></i></div>
                                            <input type="password" className="form-control" id="exampleInputEmail1" placeholder="Password"
                                                onChange={(e) => {
                                                    setPassword(e.target.value)
                                                    CheckSignUpFill()
                                                }} />
                                        </div>

                                        <div className="mb-3 d-flex">
                                            <div className="signup-icon px-3 py-1 me-2"><i className="fa-solid fa-lock"></i></div>
                                            <input type="password" className="form-control" id="exampleInputEmail1" placeholder="Confirm Password"
                                                onChange={(e) => {
                                                    setConfPassword(e.target.value)
                                                    CheckSignUpFill()
                                                }}
                                                
                                                onKeyDown={(e) => {
                                                    if (e.key == "Enter") {  //tab pressed
                                                        if (AllFieldFill) {
                                                            setLoginOnspinner(true)
    
                                                            Register_Single_User();
    
                                                        }
    
    
    
                                                        e.preventDefault()
                                                    }
                                                }}/>
                                        </div>

                                        <div className="d-flex justify-content-center">
                                            <button type="button" className={AllFieldFill ? "signup-succ" : "signup-succ-disable"}
                                                onKeyDown={(e) => {
                                                    if (e.keyCode == 9) {  //tab pressed
                                                        e.preventDefault(); // stops its action
                                                    }
                                                }}
                                                onClick={(e) => {
                                                    if (AllFieldFill) {
                                                        setOnspinner(true)

                                                        Register_Single_User();

                                                    }
                                                    e.preventDefault()
                                                }}>
                                                SignUp
                                            </button>
                                        </div>
                                    </form>

                                    <form id="signup_squad" className="input-group">
                                        <h2 className="text-white signup-text m-0 pb-3">Squad</h2>

                                        <div className="mb-3 d-flex">
                                            <div className="icon px-3 py-1 me-2"><i className="fa-solid fa-users"></i></div>
                                            <input type="name" className="form-control" id="exampleInputEmail1" placeholder="Team Name" />
                                        </div>

                                        <div className="mb-3 d-flex">
                                            <div className="icon px-3 py-1 me-2"><i className="fa-solid fa-users"></i></div>
                                            <input type="name" className="form-control" id="exampleInputEmail1" placeholder="Name" />
                                        </div>

                                        <div className="mb-3 d-flex">
                                            <div className="icon px-3 py-1 me-2"><i className="fa-solid fa-users"></i></div>
                                            <input type="nickname" className="form-control" id="exampleInputEmail1" placeholder="Nick Name" />
                                        </div>

                                        <div className="mb-2 d-flex">
                                            <div className="icon px-3 py-1 me-2"><i className="fa-solid fa-envelope"></i></div>
                                            <input type="email" className="form-control" id="exampleInputEmail1" placeholder="Email" />
                                        </div>

                                        <div className="mb-3 d-flex w-100 justify-content-end">
                                            <div className="w-50 d-flex justify-content-end"><a className="send-otp" href="">Send OTP</a></div>
                                        </div>

                                        {/* <div className="mb-2 d-flex">
                                        <div className="icon px-3 py-1 me-2"><i className="fa-solid fa-phone"></i></div>
                                        <input type="contact" className="form-control" id="exampleInputEmail1" placeholder="Contact Number" />
                                    </div>

                                    <div className="mb-3 d-flex w-100 justify-content-end">
                                        <div className="w-50 d-flex justify-content-end"><a className="send-otp" href="">Send OTP</a></div>
                                    </div> */}

                                        <div className="mb-3 d-flex">
                                            <div className="icon px-3 py-1 me-2"><i className="fa-solid fa-lock"></i></div>
                                            <input type="password" className="form-control" id="exampleInputEmail1" placeholder="Password" />
                                        </div>

                                        <div className="mb-3 d-flex">
                                            <div className="icon px-3 py-1 me-2"><i className="fa-solid fa-lock"></i></div>
                                            <input type="password" className="form-control" id="exampleInputEmail1" placeholder="Confirm Password" />
                                        </div>

                                        <div className="d-flex justify-content-center">
                                            <button type="button" className="signup-succ-squad"

                                            >

                                                SignUp
                                            </button>
                                        </div>
                                    </form>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                : 
                
                <div class="w-100 d-flex justify-content-end px-5 align-items-center"
                onClick={(e)=>{
                    // <Redirect to="/myprofile"/>
                    navigate('/myprofile/profile');
                }}>
                
                <img class="profile" src={ProfileImg}/>
                
                 <div>
                    <div class="user-name"
                  >Hi, Utsav Moradiya</div>
                    <div class="account"
                    
                   
                    >My Account <i class="fa-solid fa-angle-down"></i></div>

                </div>  
            </div>
                                }
        </div>
    </nav>)
}


export default Header;

