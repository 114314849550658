import Routes_Booking from "./Routes_Booking"
import Header from "./Header"
import Footer from "./Footer"
import {  Outlet } from "react-router-dom"

function Layout() {

    return (<>
        <Header />
        <Outlet />
        <Footer />
    </>)
}
export default Layout