import './AdminUsers.css';
import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import { EyeFilled, SettingFilled } from '@ant-design/icons';
import { Button, Table } from 'antd';
import 'antd/dist/antd.css';



function AdminUsers() {
    let navigate = useNavigate()
    const columns = [
        {
            title: 'USERNAME',
            dataIndex: 'USERNAME',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "15px", textAlign: "center" }
                    },
                    children: <div>{text}</div>
                }
            }
        },
        {
            title: 'NAME',
            dataIndex: 'NAME',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "15px", textAlign: "center" }
                    },
                    children: <div>{text}</div>
                }
            }
        },
        {
            title: 'EMAIL',
            dataIndex: 'EMAIL',
            render(text, record) {
                return {
                    props: {
                        style: { color: "white", fontSize: "15px" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: 'MOBILE NUMBER',
            dataIndex: 'MOBILENUMBER',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "15px", textAlign: "center" }
                    },
                    children: <div>{text}</div>
                }
            }
        },
        {
            title: 'REMAINING BALANCE',
            dataIndex: 'REMAININGBALANCE',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "15px", textAlign: "center" }
                    },
                    children: <div>{text}</div>
                }
            }
        },

        {
            title: 'ACTION',
            colSpan: 2,
            dataIndex: 'Setting',
            render: () => <SettingFilled style={{ color: "#99FC03", fontSize: "18px", display: "flex", justifyContent: "center", marginLeft: "50px" }} />

        },
        {
            title: 'ACTION',
            colSpan: 0,
            dataIndex: 'Eye',
            render: (text, record, index) => <EyeFilled style={{ color: "#99FC03", fontSize: "18px", display: "flex", justifyContent: "center", marginRight: "50px" }}
                onClick={() => {
                    navigate(`/administration/usersprofile/${record['id']}`)
                }}
            />

        },
    ];


    useEffect(() => {
        var url = window.API_PREFIX + "admin/all_user_detail";

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify({ "Token": localStorage.getItem("Token") }),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            if (data['status'] == "1") {
                setUserData([...data['UserData']])

            }
        })

    }, [])
    //     const data = [];

    // for (let i = 0; i < 20; i++) {
    //     data.push({
    //         id: i,
    //         USERNAME: 'aba',
    //         NAME: `Surbhi`,
    //         EMAIL: 'surbhi@nyshasolutions.com',
    //         MOBILENUMBER:9727772204,
    //         REMAININGBALANCE: '254.00',
    //     });
    // }

    const [UserData, setUserData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;
    return (
        <>
            <div>
                <div className='adminusers_main_division'>
                    <div className='adminusers_users_title'>USERS</div>

                    <div className='adminusers_sub_division'>
                        <div className='adminusers_searchfield_division'>
                            <div class="d-flex admin_search_field_division" role="search">
                                <input class="form-control me-2 admin_text_search" type="search" placeholder="Search users" aria-label="Search" />
                                <i class="fa-solid fa-magnifying-glass admin_search_icon"></i>
                            </div>
                            <div className='adminusers_date_icon'>22 March 2022 <i class="fa-solid fa-angle-down p-2"></i></div>
                        </div>

                        <div className='adminusers_table_main_division'>
                            <div
                                style={{
                                    marginBottom: 16,
                                }}>
                                <span className='all_products_data_table'
                                    style={{
                                        marginLeft: 8,
                                    }}
                                >
                                    {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
                                </span>
                            </div>
                            <div className='w-100 allproduct_table_number'>
                                <Table className='all_products_tables' rowSelection={rowSelection} columns={columns} dataSource={UserData} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default AdminUsers;